import React from "react";
const LighterIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28 22C28.5523 22 29 21.5523 29 21C29 20.4477 28.5523 20 28 20C27.4477 20 27 20.4477 27 21C27 21.5523 27.4477 22 28 22Z" fill="#F05B2B"/>
        <path d="M32 21C32 21.5523 31.5523 22 31 22C30.4477 22 30 21.5523 30 21C30 20.4477 30.4477 20 31 20C31.5523 20 32 20.4477 32 21Z" fill="#F05B2B"/>
        <path d="M34 22C34.5523 22 35 21.5523 35 21C35 20.4477 34.5523 20 34 20C33.4477 20 33 20.4477 33 21C33 21.5523 33.4477 22 34 22Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 20.126L20 7C20 6.44772 19.5523 6 19 6H13C9.13401 6 6 9.13401 6 13V21C6 24.866 9.13401 28 13 28H13.3333C13.8856 28 14.3333 27.5523 14.3333 27C14.3333 26.4477 13.8856 26 13.3333 26H13C10.2386 26 8 23.7614 8 21L8 13C8 10.2386 10.2386 8 13 8L18 8L18 20.126C16.2748 20.5701 15 22.1362 15 24C15 25.8638 16.2748 27.4299 18 27.874V35C18 38.866 21.134 42 25 42H33C36.866 42 40 38.866 40 35V24C40 23.4477 39.5523 23 39 23H38V20C38 18.3433 36.657 17.0002 35.0004 17C35.628 16.1643 36 15.1256 36 14C36 13.0868 35.9201 11.7355 35.5889 10.4129C35.2652 9.11981 34.6576 7.67676 33.4696 6.79353C33.074 6.49942 32.6153 6.35253 32.1375 6.38712C31.6763 6.4205 31.2835 6.61617 30.979 6.8542C30.3874 7.31665 29.9859 8.04325 29.8119 8.74639C29.4792 10.0917 28.761 10.6926 28.0013 11.2297C27.9394 11.2734 27.8727 11.3196 27.8027 11.368C27.4713 11.5972 27.067 11.8768 26.7594 12.1908C26.3277 12.6315 26 13.2111 26 14C26 15.1256 26.3719 16.1643 26.9996 17C25.3429 17.0002 24 18.3433 24 20L24 24C24 24.5178 24.3935 24.9436 24.8978 24.9948C24.9314 24.9983 24.9655 25 25 25H38V35C38 37.7614 35.7614 40 33 40H25C22.2386 40 20 37.7614 20 35V27.874C21.7252 27.4299 23 25.8638 23 24C23 22.1362 21.7252 20.5701 20 20.126ZM31.0048 17H30.9952C29.3405 16.9974 28 15.6552 28 14C28 13.8406 28.0409 13.7406 28.1882 13.5903C28.3498 13.4252 28.5534 13.2835 28.8679 13.0645C28.9548 13.0039 29.0506 12.9372 29.1559 12.8628C30.079 12.2101 31.2523 11.2526 31.7534 9.22663C31.8447 8.85751 32.0451 8.55938 32.2107 8.42992C32.2348 8.41114 32.2536 8.39929 32.2671 8.3919L32.2764 8.39857C32.908 8.86814 33.364 9.76103 33.6488 10.8987C33.9263 12.0068 34 13.1811 34 14C34 15.6552 32.6595 16.9974 31.0048 17ZM27 19C26.4477 19 26 19.4477 26 20V23H36V20C36 19.4477 35.5523 19 35 19L31 19L27 19ZM19 22C17.8954 22 17 22.8954 17 24C17 25.1046 17.8954 26 19 26C20.1046 26 21 25.1046 21 24C21 22.8954 20.1046 22 19 22Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 35C22 36.6569 23.3431 38 25 38H33C34.6569 38 36 36.6569 36 35V31C36 29.3431 34.6569 28 33 28H25C23.3431 28 22 29.3431 22 31V35ZM25 36C24.4477 36 24 35.5523 24 35V31C24 30.4477 24.4477 30 25 30H33C33.5523 30 34 30.4477 34 31V35C34 35.5523 33.5523 36 33 36H25Z" fill="#F05B2B"/>
    </svg>;
};
export default LighterIcon;
