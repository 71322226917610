import React from "react";
const ToxicIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 24C21 22.3431 22.3431 21 24 21C25.6569 21 27 22.3431 27 24C27 25.6569 25.6569 27 24 27C22.3431 27 21 25.6569 21 24ZM24 23C23.4477 23 23 23.4477 23 24C23 24.5523 23.4477 25 24 25C24.5523 25 25 24.5523 25 24C25 23.4477 24.5523 23 24 23Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0186 13.0356C18.4903 12.0902 17.2374 11.6817 16.2495 12.3396C12.7648 14.6602 10.377 18.5045 10.041 22.9249C9.95114 24.1063 10.9175 25 12.0002 25H17.0002C18.1383 25 18.9145 24.0934 19.0688 23.1691C19.2748 21.9358 19.9335 20.853 20.8718 20.0993C21.6018 19.513 22.013 18.3939 21.4584 17.4014L19.0186 13.0356ZM12.0413 23C12.3482 19.2753 14.3572 16.0306 17.2932 14.0478L19.7124 18.377C19.7127 18.3797 19.7134 18.3906 19.7088 18.4084C19.6987 18.448 19.6693 18.4999 19.6193 18.5401C18.3103 19.5916 17.3855 21.1067 17.0961 22.8397C17.0856 22.9029 17.0547 22.9538 17.0253 22.9818C17.012 22.9944 17.0029 22.9988 17.0005 22.9998L12.0413 23Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.942 12.4691C30.965 11.795 29.7055 12.1828 29.1617 13.1194L26.6504 17.4443C26.0796 18.4274 26.4722 19.5532 27.1925 20.1515C28.0969 20.9026 28.7304 21.964 28.9317 23.1691C29.086 24.0935 29.8622 25 31.0002 25H36.0002C37.083 25 38.0493 24.1063 37.9595 22.9248C37.6297 18.5857 35.3227 14.8016 31.942 12.4691ZM28.38 18.4485L30.8703 14.1598C33.7176 16.1517 35.658 19.3447 35.9592 23H31.0004C30.998 22.999 30.9885 22.9944 30.9752 22.9818C30.9458 22.9538 30.9149 22.903 30.9044 22.8397C30.6216 21.1464 29.7322 19.661 28.4704 18.613C28.4211 18.572 28.3926 18.5197 28.3831 18.4799C28.3789 18.462 28.3797 18.4512 28.38 18.4485Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2154 28.6723C21.3409 28.338 20.1656 28.5466 19.5882 29.5282L17.0525 33.8388C16.5043 34.7708 16.7825 36.0569 17.8472 36.5786C19.7055 37.4892 21.7947 38 24.0002 38C26.2058 38 28.295 37.4892 30.1533 36.5786C31.218 36.0569 31.4962 34.7708 30.9479 33.8388L28.4123 29.5282C27.8349 28.5466 26.6596 28.338 25.7851 28.6723C25.2321 28.8837 24.6309 29 24.0002 29C23.3696 29 22.7684 28.8837 22.2154 28.6723ZM18.7976 34.8168L21.312 30.5423C21.3142 30.5407 21.3232 30.5346 21.3411 30.5295C21.3806 30.5182 21.4408 30.5173 21.5012 30.5405C22.2787 30.8377 23.1217 31 24.0002 31C24.8788 31 25.7218 30.8377 26.4992 30.5405C26.5597 30.5173 26.6199 30.5182 26.6594 30.5295C26.6772 30.5346 26.6858 30.5403 26.688 30.5419L29.2029 34.8168C27.6302 35.5748 25.8663 36 24.0002 36C22.1342 36 20.3703 35.5748 18.7976 34.8168Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24ZM24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8Z" fill="#F05B2B"/>
    </svg>;
};
export default ToxicIcon;
