/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import Image from "./Image";
import { generateYoutubePosterLink } from "../helpers/linkGenerators";
import { allowFullScreenVideo, CLEVERTAP_ACTIONS } from "../helpers/constants";
import { sendClevertapEvent } from "../helpers/misc";
import { DATA_REFS } from "../helpers/constants/dataRefs";
/**
 * Params for the Youtube video
 * Refer - https://developers.google.com/youtube/player_parameters
 * NOTE - We set autoplay to true because we're loading a placeholder
 * image first, and only after a click do we load the iframe. The user
 * then shouldn't have to click the play button again to start the
 * video
 * @type {string}
 */
const embedOptions = "modestbranding=1&autoplay=true";
const applyYoutubeOptionsToUrl = (embedSrc) => {
    if (embedSrc.indexOf("?") > -1) {
        return `${embedSrc}&${embedOptions}`;
    }
    return `${embedSrc}?${embedOptions}`;
};
const IframeEmbed = (props) => (<iframe src={applyYoutubeOptionsToUrl(props.embedSrc)} frameBorder={0} allowFullScreen={allowFullScreenVideo} data-ref={props.dataRef}/>);
const videoEmbedImageStyle = `
	img {
		width: 100%;
	}
`;
class VideoEmbed extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showEmbed: false
        };
        this.setShowEmbed = this.setShowEmbed.bind(this);
    }
    setShowEmbed() {
        this.setState({
            showEmbed: true
        });
        if (this.props.shouldFireClevertapEvent) {
            sendClevertapEvent(CLEVERTAP_ACTIONS.VIDEO_CLICK, {
                clevertapFireLocation: this.props.clevertapFireLocation
            });
        }
    }
    generateImageSrc() {
        return this.props.coverImage && this.props.coverImage !== ""
            ? this.props.coverImage
            : generateYoutubePosterLink(this.props.embedSrc);
    }
    /**
     * Chooses whether to display the placeholder or render
     * the iframe
     * @todo This only needs to happen for Youtube embeds
     */
    selectChildComponent() {
        if (!this.state.showEmbed) {
            return (<Image src={this.generateImageSrc()} className={"cursor-action image-size-large"} optimize={false} onClick={this.setShowEmbed} imgWidth={this.props.imgWidth} imgWidths={this.props.imgWidths} sizeString={this.props.sizeString} dataRef={DATA_REFS.EDP_VIDEO_EMBED}/>);
        }
        return <IframeEmbed embedSrc={this.props.embedSrc} dataRef={DATA_REFS.EDP_VIDEO_EMBED}/>;
    }
    render() {
        return <div className={this.props.videoClassName} css={css([this.props.headerVideoCSS, videoEmbedImageStyle])}>{this.selectChildComponent()}</div>;
    }
}
VideoEmbed.defaultProps = {
    imgWidth: 1000,
    imgWidths: [500, 650, 1000, 800],
    sizeString: "(max-width:35em) 500px, (max-width:48em) 650px, (max-width:64em) 1000px, 800px",
    shouldFireClevertapEvent: false,
    clevertapFireLocation: ""
};
export default VideoEmbed;
