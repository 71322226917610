/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import theme, { colors, smallFontSize } from "../../styles/common";
import GreenTickIcon from "../ui/icons/GreenTickIcon";
import RedCrossIcon from "../ui/icons/RedCrossIcon";
const mainDivCss = `
	padding: 18px;
	background-color: rgb(49, 192, 240, 0.05);
	border-radius: 9px;
	${theme.mediaQueries["for-mobile"]} {
		margin: 0 auto;
		width: 88vw;
		border-radius: 0;
		padding-left: 0;
	}
	${theme.mediaQueries["for-tablet-only"]} {
		margin-left: auto;
	}
`;
const logoCss = `
	width: 21px;
	vertical-align: sub;
`;
const titleCss = `
	font-size: 19px;
	font-weight: bold;
	padding-left: 1%;
	color: ${colors.descriptionColor};
	display: inline-block;
	${theme.mediaQueries["for-large-tablet"]} {
		font-size: 21px;
	}
`;
const subTextCss = `
	display: block;
	font-weight: normal;
	padding-bottom: 12px;
	color: #45474D;
	padding-top: 0;
	padding-right: 18px;
	${theme.mediaQueries["for-mobile"]} {
		padding-top: 12px;
	}
`;
const capsuleDivCss = (length = 1) => `
	display: inline-block;
	width: 100%;
	${theme.mediaQueries["for-mobile"]} {
		display: flex;
		flex-wrap: wrap;
		overflow-x: scroll;
		overflow-y: hidden;
		width: ${245 * length}px;
		max-width: 755px;
		padding-left: 18px;
	}
`;
const capsuleCss = `
	border: 0.5px solid rgb(49, 192, 240, 0.5);;
	margin-top: 1%;
	margin-right: 1%;
	margin-bottom: 0.3%;
	border-radius: 25px;
	padding: 5px;
	display: inline-block;
	font-size: ${smallFontSize};
	padding-right: 10px;
	padding-left: 10px;
	${theme.mediaQueries["for-mobile"]} {
		white-space: nowrap;
    }
    svg{
        padding-right: 5px;
	    width: 18px;
    }
`;
const horizontalScrollableCss = `
	overflow-x: auto;
	height: auto
	${theme.mediaQueries["for-mobile"]} {
		height: 90px;
	}
`;
const boldBlueText = `
	font-weight: bold;
	color: ${colors.primaryBlue};
`;
const textDivCss = `
	${theme.mediaQueries["for-mobile"]} {
		padding-left: 18px;
	}
`;
const renderCapsules = (safety_measures) => {
    const capsules = safety_measures
        .filter(x => x.get("is_available"))
        .map(step => {
        return (step && (<span css={css(capsuleCss)}>
						{step.get("is_available") ? <GreenTickIcon /> : <RedCrossIcon />}
						{step.get("content")}
					</span>));
    });
    return capsules;
};
const EventSafetyMeasures = (props) => {
    const capsules = renderCapsules(props.safety_measures);
    return (capsules.size > 0) && (<div css={css(mainDivCss)}>
			<div css={css(textDivCss)}>
				<img css={css(logoCss)} src="/assets/img/SafetyLogo.svg"/>
				<span css={css(titleCss)}>Covid 19 Safety Measures</span>
				<span css={css(subTextCss)}>
					This event adheres to the following <span css={css(boldBlueText)}>Health & safety measures</span>
				</span>
			</div>
			<div className={"overflow-true"} css={css(horizontalScrollableCss)}>
				<div css={css(capsuleDivCss(capsules.size))}>{capsules}</div>
			</div>
		</div>);
};
export default EventSafetyMeasures;
