import React from "react";
import classNames from "classnames";
const CardListWrapper = ({ children, essentialClass, className }) => {
    return <div className={classNames("card-list-wrapper", essentialClass, className)}>{children}</div>;
};
CardListWrapper.defaultProps = {
    className: "",
    essentialClass: "card-grid"
};
export default CardListWrapper;
