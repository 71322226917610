import React from "react";
import InsiderLoadable from "../InsiderLoadable";
import TextActionPanel from "../TextActionPanel";
export default class AsyncSubscribeActionPanel extends React.Component {
    constructor() {
        super(...arguments);
        // create this per instance so it's not cached for SSR
        this.subscribeComponent = InsiderLoadable({
            loader: () => import(/* webpackChunkName: "SubscribeComponent" */ "../../containers/SubscribeWithSubscription")
        });
    }
    shouldComponentUpdate(nextProps) {
        // Maybe this should return false because this is a fairly static component
        const { title, text, className } = this.props;
        return title !== nextProps.title || text !== nextProps.text || className !== nextProps.className;
    }
    render() {
        const { title, text, className, onSuccess } = this.props;
        const Subscribe = this.subscribeComponent;
        return (<TextActionPanel title={title} text={text} className={className} actionComponent={<Subscribe onSuccess={onSuccess}/>}/>);
    }
}
AsyncSubscribeActionPanel.defaultProps = {
    title: "Subscribe to receive our newsletter!",
    text: "Find the best experiences happening out there in your city or enjoy handpicked content while sitting comfortably at home. Paytm Insider is your go-to place to figure out what to do today!",
    className: "insider-action"
};
