import React from "react";
const PowerBank = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.8892 25.5424C21.7178 25.2093 21.3746 25 21 25H15.9432L19.8137 19.5812C20.1347 19.1318 20.0306 18.5073 19.5812 18.1863C19.1318 17.8653 18.5073 17.9693 18.1863 18.4188L13.1863 25.4188C12.9685 25.7236 12.9394 26.1245 13.1108 26.4576C13.2822 26.7907 13.6254 27 14 27H19.0568L15.1863 32.4188C14.8653 32.8682 14.9693 33.4927 15.4188 33.8137C15.8682 34.1347 16.4927 34.0306 16.8137 33.5812L21.8137 26.5812C22.0315 26.2764 22.0606 25.8755 21.8892 25.5424Z" fill="#F05B2B"/>
        <path d="M13 38C13.5523 38 14 37.5523 14 37C14 36.4477 13.5523 36 13 36C12.4477 36 12 36.4477 12 37C12 37.5523 12.4477 38 13 38Z" fill="#F05B2B"/>
        <path d="M18 37C18 37.5523 17.5523 38 17 38C16.4477 38 16 37.5523 16 37C16 36.4477 16.4477 36 17 36C17.5523 36 18 36.4477 18 37Z" fill="#F05B2B"/>
        <path d="M21 38C21.5523 38 22 37.5523 22 37C22 36.4477 21.5523 36 21 36C20.4477 36 20 36.4477 20 37C20 37.5523 20.4477 38 21 38Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 24H34C32.8954 24 32 24.8954 32 26V31C32 32.1046 32.8954 33 34 33V35C34 36.1046 34.8954 37 36 37H38C39.1046 37 40 36.1046 40 35V33C41.1046 33 42 32.1046 42 31V26C42 24.8954 41.1046 24 40 24H38V8C38 6.89543 37.1046 6 36 6H21C19.8954 6 19 6.89543 19 8V10H18C16.8954 10 16 10.8954 16 12V14H10C7.79086 14 6 15.7909 6 18V38C6 40.2091 7.79086 42 10 42H24C26.2091 42 28 40.2091 28 38L28 18C28 15.7909 26.2091 14 24 14L24 12C24 10.8954 23.1046 10 22 10H21V8L36 8V24ZM20 12L22 12V14H18V12L20 12ZM34 26V31H40V26H34ZM10 16C8.89543 16 8 16.8954 8 18L8 38C8 39.1046 8.89543 40 10 40H24C25.1046 40 26 39.1046 26 38L26 18C26 16.8954 25.1046 16 24 16L10 16ZM36 35V33H38V35H36Z" fill="#F05B2B"/>
    </svg>;
};
export default PowerBank;
