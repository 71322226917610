/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Image from "./Image";
import Carousel from "./Carousel";
import { stripHrefDomain } from "../helpers/misc";
import { generateEventLinkFromHref } from "../helpers/linkGenerators";
import { imageCaptionCSS, imageContainer } from "../styles/modules/imageStyles";
import { mediaFeatures } from "../styles/common";
import { DATA_REFS } from "../helpers/constants/dataRefs";
const eventGalleryCarouselSection = `
	@media screen and ${mediaFeatures["for-pc"]}{
		overflow: visible;
	}
`;
const ImageTitle = props => {
    if (props.data.getIn(["content", "imageListTitle"])) {
        return <h2 className="image-list-title">{props.data.getIn(["content", "imageListTitle"])}</h2>;
    }
    return null;
};
class ImageList extends React.PureComponent {
    getTrueFirstChildDimensions() {
        const height = this.props.data.getIn(["content", "images", 0, "height"]);
        const width = this.props.data.getIn(["content", "images", 0, "width"]);
        if (height && width) {
            return {
                height,
                width
            };
        }
    }
    renderImageCarousel() {
        const images = this.props.data.getIn(["content", "images"]).map((image, index) => (
        // can have duplicate keys here, if there are multiple image lists in the same article
        // use something more unique and determenistic that just index
        <li key={`image-${image.get("src")}`} className="image-list-item">
				<Image src={image.get("src")} className="variable-height" caption={image.get("caption")} href={generateEventLinkFromHref(stripHrefDomain(image.get("href")), this.props.isFromApp)} captionCSS={imageCaptionCSS} imageHeight={image.get("height")} imageWidth={image.get("width")}/>
			</li>));
        const isGrid = this.props.data.getIn(["metadata", "display"]) === "grid";
        const isLimitedCarousel = this.props.data.getIn(["metadata", "display"]) === "carousel-limited";
        return (<section className={`image-list-wrapper image-${this.props.data.getIn(["metadata", "display"])}`} css={css(eventGalleryCarouselSection)} data-ref={DATA_REFS.IMAGE_LIST}>
				<ImageTitle data={this.props.data}/>
				<Carousel isLimitedCarousel={isLimitedCarousel} carouselType={isGrid ? "gallery" : "event-gallery"} listType="image" variableHeight={true} trueFirstChildDimensions={this.getTrueFirstChildDimensions()} numCarouselItems={this.props.data.getIn(["metadata", "numCarouselItems"])} carouselCSS={this.props.carouselCSS}>
					{images}
				</Carousel>
			</section>);
    }
    renderImageCard() {
        return (<Image src={this.props.data.getIn(["content", "images", 0, "src"])} css={css(imageContainer)} className={`image-${this.props.data.getIn(["metadata", "align"])} image-${this.props.data.getIn([
                "metadata",
                "wrap"
            ])} image-size-${this.props.data.getIn(["metadata", "size"])}`} caption={this.props.data.getIn(["content", "images", 0, "caption"])} href={generateEventLinkFromHref(stripHrefDomain(this.props.data.getIn(["content", "images", 0, "href"])), this.props.isFromApp)} captionCSS={imageCaptionCSS}/>);
    }
    render() {
        const imageList = this.props.data.getIn(["content", "images"]);
        if (imageList.size > 1) {
            return this.renderImageCarousel();
        }
        return this.renderImageCard();
    }
}
export default ImageList;
