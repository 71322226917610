/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import * as React from "react";
import { imageContainer } from "../../styles/modules/imageStyles";
import RichTextTitle from "../RichTextTitle";
const containerStyle = {
    margin: "0 auto",
    display: "block",
    textAlign: "center"
};
const SeatMapImage = ({ imageURL, redirectURL }) => {
    return (<React.Fragment>
			<RichTextTitle text="Select Your Seats"/>
			<section className="text text-left">
				<figure css={css(imageContainer)} className="image-size-big image-left">
					<a css={containerStyle} href={redirectURL}>
						<img style={{ width: "90%" }} src={imageURL}></img>
					</a>
				</figure>
			</section>
		</React.Fragment>);
};
export default SeatMapImage;
