import React from "react";
const OutsideFoodIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 8.00001L27.2562 8.00001L29.6562 16H25C24.4477 16 24 16.4477 24 17C24 17.5523 24.4477 18 25 18H25.1172L26.6484 30.249C26.7169 30.7971 27.2166 31.1858 27.7647 31.1173C28.3127 31.0488 28.7014 30.549 28.6329 30.001L27.1328 18H30.9766C30.9921 18.0004 31.0076 18.0004 31.0232 18H38.8672L38.3672 22H32C31.7204 22 31.4535 22.1171 31.2641 22.3229C31.0748 22.5287 30.9802 22.8044 31.0035 23.0831L32.0035 35.0831C32.0466 35.6013 32.4799 36 33 36H36.6172L36.1172 40H29.8828L29.6173 37.876C29.5488 37.328 29.049 36.9392 28.501 37.0077C27.953 37.0762 27.5642 37.576 27.6327 38.124L28.0077 41.124C28.0703 41.6245 28.4957 42 29 42H37C37.5043 42 37.9297 41.6245 37.9923 41.124L40.8828 18H41C41.5523 18 42 17.5523 42 17C42 16.4477 41.5523 16 41 16H40.0019L40 16H31.7443L28.9596 6.71758C28.9535 6.69709 28.9469 6.67688 28.9396 6.65696C28.8601 6.43863 28.711 6.26334 28.5252 6.14888C28.4336 6.09223 28.3322 6.04995 28.224 6.0252C28.1443 6.00683 28.0618 5.99812 27.9783 6.00001L21 6.00001C20.4477 6.00001 20 6.44772 20 7.00001C20 7.55229 20.4477 8.00001 21 8.00001ZM33.0868 24H38.1172L36.8672 34H33.9201L33.0868 24Z" fill="#F05B2B"/>
        <path d="M9 36C9.55228 36 10 36.4477 10 37C10 38.6569 11.3431 40 13 40H21C22.6569 40 24 38.6569 24 37C24 36.4477 24.4477 36 25 36C25.5523 36 26 36.4477 26 37C26 39.7614 23.7614 42 21 42H13C10.2386 42 8 39.7614 8 37C8 36.4477 8.44772 36 9 36Z" fill="#F05B2B"/>
        <path d="M10 31C10 31.5523 9.55228 32 9 32C8.44772 32 8 31.5523 8 31C8 27.134 11.134 24 15 24H19C22.866 24 26 27.134 26 31C26 31.5523 25.5523 32 25 32C24.4477 32 24 31.5523 24 31C24 28.2386 21.7614 26 19 26H15C12.2386 26 10 28.2386 10 31Z" fill="#F05B2B"/>
        <path d="M7 33C6.44772 33 6 33.4477 6 34C6 34.5523 6.44772 35 7 35L27 35C27.5523 35 28 34.5523 28 34C28 33.4477 27.5523 33 27 33L7 33Z" fill="#F05B2B"/>
        <path d="M13 31C13.5523 31 14 30.5523 14 30C14 29.4477 13.5523 29 13 29C12.4477 29 12 29.4477 12 30C12 30.5523 12.4477 31 13 31Z" fill="#F05B2B"/>
        <path d="M18 29C18 29.5523 17.5523 30 17 30C16.4477 30 16 29.5523 16 29C16 28.4477 16.4477 28 17 28C17.5523 28 18 28.4477 18 29Z" fill="#F05B2B"/>
        <path d="M21 31C21.5523 31 22 30.5523 22 30C22 29.4477 21.5523 29 21 29C20.4477 29 20 29.4477 20 30C20 30.5523 20.4477 31 21 31Z" fill="#F05B2B"/>
    </svg>;
};
export default OutsideFoodIcon;
