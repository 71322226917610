import React from "react";
const PetIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.6401 8L25 8.00001C25.5523 8.00001 26 8.44773 26 9.00001L26 17.9957C24.3286 17.909 23 16.5263 23 14.8333V13.9091C23 13.3568 22.5523 12.9091 22 12.9091C21.4477 12.9091 21 13.3568 21 13.9091V14.8333C21 17.6868 23.3132 20 26.1667 20C27.1792 20 28 19.1792 28 18.1667L28 9.00001C28 7.34316 26.6569 6.00001 25 6.00001L19.6401 6C17.039 6 14.753 7.72428 14.0385 10.2253C13.5692 11.8677 12.0681 13 10.36 13L7 13C6.44772 13 6 13.4477 6 14V17.2328C6 20.4179 8.58205 23 11.7672 23C12.8852 23 13.9455 23.4966 14.6612 24.3555L16.6152 26.7003C17.028 27.1957 16.8709 27.9465 16.2942 28.2349L14.0807 29.3416C10.354 31.2049 8 35.0138 8 39.1803L8 41C8 41.5523 8.44772 42 9 42C9.55229 42 10 41.5523 10 41V39.1803C10 35.7714 11.926 32.655 14.9751 31.1305L17.1886 30.0237C18.9189 29.1586 19.3901 26.9061 18.1516 25.4199L16.1976 23.0751C15.1019 21.7602 13.4788 21 11.7672 21C9.68662 21 8 19.3134 8 17.2328V17C9.10457 17 10 16.1046 10 15H10.36C12.961 15 15.247 13.2757 15.9615 10.7747C16.4308 9.13233 17.9319 8 19.6401 8Z" fill="#F05B2B"/>
        <path d="M31.7031 19.2396C32.9345 18.9933 34.0026 20.1136 33.6981 21.3318L33.3553 22.7031C33.2842 22.9873 33.4651 23.2732 33.7524 23.3306L34.6451 23.5091C36.5746 23.8951 38.1495 25.2853 38.7717 27.1521C38.7838 27.1882 38.8121 27.2165 38.8482 27.2285L40.5188 27.7854C41.4035 28.0803 42.0003 28.9082 42.0003 29.8408C42.0003 32.1577 40.5897 34.2413 38.4384 35.1018L37.2471 35.5783C37.7674 37.1618 37.764 38.871 37.2368 40.4528L36.9489 41.3162C36.7743 41.8402 36.208 42.1233 35.684 41.9487C35.1601 41.774 34.8769 41.2077 35.0516 40.6838L35.3394 39.8204C35.7333 38.6387 35.7333 37.3612 35.3394 36.1796C35.0103 35.1925 35.5078 34.12 36.4739 33.7335L37.6956 33.2448C39.0876 32.688 40.0003 31.3399 40.0003 29.8408C40.0003 29.7691 39.9544 29.7055 39.8864 29.6828L38.2157 29.1259C37.5824 28.9148 37.0855 28.4179 36.8744 27.7845C36.4791 26.5986 35.4786 25.7155 34.2528 25.4703L33.3601 25.2918C31.9532 25.0104 31.067 23.61 31.415 22.218L31.6441 21.3016C29.9534 21.7615 28.6772 23.1765 28.4079 24.927L27.8986 28.2371C27.2873 32.2109 24.3 35.4022 20.3752 36.2744C18.7217 36.6419 17.3564 37.8023 16.7273 39.375L15.9287 41.3714C15.7236 41.8842 15.1417 42.1336 14.6289 41.9285C14.1161 41.7234 13.8667 41.1414 14.0718 40.6286L14.8703 38.6322C15.7448 36.446 17.6427 34.8328 19.9413 34.322C23.0612 33.6287 25.4359 31.0919 25.9219 27.933L26.4311 24.6228C26.8438 21.9408 28.8884 19.8026 31.5493 19.2704L31.7031 19.2396Z" fill="#F05B2B"/>
        <path d="M34 28C33.4477 28 33 28.4477 33 29C33 29.5523 33.4477 30 34 30C34.5523 30 35 29.5523 35 29C35 28.4477 34.5523 28 34 28Z" fill="#F05B2B"/>
        <path d="M16 14C16 13.4477 16.4477 13 17 13C17.5523 13 18 13.4477 18 14C18 14.5523 17.5523 15 17 15C16.4477 15 16 14.5523 16 14Z" fill="#F05B2B"/>
    </svg>;
};
export default PetIcon;
