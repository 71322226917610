/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Image from "./Image";
import theme, { colors } from "../styles/common";
import { DIGITAL_EVENTS_ICON } from "../helpers/constants/common";
const SalientFeatures = (props) => {
    const { salient_features, venue, type } = props;
    const salientFeaturesLayout = `
        display: flex;
        flex-direction: row;
    `;
    const salientFeaturesTitle = `
        ${theme.mediaQueries["for-mobile"]}{
			font-size: 12px;
			line-height: 1.75;
		};
        font-size: 14px;
        color: ${colors.subTextGrey};
    `;
    const salientFeaturesDescription = `
		${theme.mediaQueries["for-mobile"]}{
			line-height: 1.31;
			margin-top: 0;
		};
        color: ${colors.descriptionColor};
		font-size: 16px;
		margin-top: 2px;
		
    }`;
    const FeatureLayout = `
        ${theme.mediaQueries["for-mobile"]}{
            padding-top:0;
        };
        padding-top: 18px;
    `;
    const seperatorStyle = `
        ${theme.mediaQueries["for-mobile"]}{
            display:none;
        };
        height: 1;
        border: solid 0.5px ${colors.faintGrey};
    `;
    const headingStyle = `
    font-size: 16px;
    margin-bottom: 18px;
    font-weight: bold;
    `;
    const edpIconStyle = `
		width: 28px;
		height: 28px;
		margin-right: 18px;
		margin-top: 5px;
		div	{
			overflow-y: hidden;
		}
		img	{
			width: 100%;
		}
	`;
    const dataCards = (salient_features) => {
        const data = salient_features.map((feature, i) => {
            return (<div key={`salient-features-${i}`} css={[css(salientFeaturesLayout), { paddingBottom: 30 }]}>
					<Image css={css(edpIconStyle)} src={feature.get("image_url")}/>
					<div>
						<p css={css(salientFeaturesTitle)}>{feature.get("title")}</p>
						<p css={css(salientFeaturesDescription)}>{feature.get("text")}</p>
					</div>
				</div>);
        });
        return data;
    };
    const isPhysicalEvent = type === "physical";
    return (<React.Fragment>
			<div css={css(headingStyle)}>Event Guide</div>
			<div css={css(seperatorStyle)}></div>
			<div css={css(FeatureLayout)}>
				{/* first is always venue */}
				{!isPhysicalEvent && (<div css={[css(salientFeaturesLayout), { paddingBottom: 30 }]}>
					<Image css={css(edpIconStyle)} src={DIGITAL_EVENTS_ICON}/>
					<div>
						<p css={css(salientFeaturesTitle)}>Digital Event</p>
						<p css={css(salientFeaturesDescription)}>{venue}</p>
					</div>
				</div>)}
				{dataCards(salient_features)}
			</div>
		</React.Fragment>);
};
export default SalientFeatures;
