/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as React from "react";
import theme from "../../styles/common";
const shareTitleStyle = `
	font-size: 16px;
	font-weight: bold;
	letter-spacing: -0.2px;
`;
const shareSubTitleStyle = `
	font-size: 12px;
	letter-spacing: -0.3px;
	color: #45474d;
	margin-top: 8px;
`;
const EDPShareDiv = `
    ${theme.mediaQueries["for-mobile-and-tablet"]} {
        flex: 3
    };`;
const shareButtons = `
${theme.mediaQueries["for-pc"]} {
    position: absolute; 
    right: 0;
	display: flex;
	justify-content: flex-end;
	width: 68px;
	height: 28px;
}`;
const mobileShareStyle = `
	width:88vw;
	margin:auto;
	display: flex;
	flex-direction: row;
	margin-top:18px;
`;
const EDPShare = (props) => {
    return (<React.Fragment>
			<div css={css(mobileShareStyle)}>
				<div css={css(EDPShareDiv)}>
					<p css={css(shareTitleStyle)}>Invite your friends</p>
					<p css={css(shareSubTitleStyle)}>and enjoy a shared experience</p>
				</div>
				<div css={css(shareButtons)}>{props.showShareButtons}</div>
			</div>
			{props.seperator}
		</React.Fragment>);
};
export default EDPShare;
