import React from "react";
const TobaccoIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_2975_983" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M19.3535 5.35355C19.5487 5.15829 19.5487 4.84171 19.3535 4.64645C19.1582 4.45118 18.8416 4.45118 18.6464 4.64645L16.7928 6.5H4.5C4.32353 6.5 4.16013 6.59302 4.07005 6.74477C3.97997 6.89651 3.97655 7.08451 4.06105 7.23943L4.61227 8.25L4.06105 9.26057C3.97965 9.40981 3.97965 9.59019 4.06105 9.73943L4.61227 10.75L4.06105 11.7606C3.97965 11.9098 3.97965 12.0902 4.06105 12.2394L4.61227 13.25L4.06105 14.2606C3.97965 14.4098 3.97965 14.5902 4.06105 14.7394L4.61227 15.75L4.06105 16.7606C3.97655 16.9155 3.97997 17.1035 4.07005 17.2552C4.16013 17.407 4.32353 17.5 4.5 17.5H5.79282L4.50424 18.7886C4.30897 18.9838 4.30897 19.3004 4.50424 19.4957C4.6995 19.691 5.01608 19.691 5.21134 19.4957L7.34832 17.3587C7.35184 17.3553 7.3553 17.3518 7.35871 17.3483L15.3483 9.35871C15.3518 9.3553 15.3553 9.35184 15.3587 9.34832L17.3483 7.3587C17.3518 7.35529 17.3553 7.35184 17.3587 7.34833L19.3535 5.35355ZM15.7928 7.5H5.34227L5.62077 8.01057C5.70217 8.15981 5.70217 8.34019 5.62077 8.48943L5.06954 9.5L5.62077 10.5106C5.70217 10.6598 5.70217 10.8402 5.62077 10.9894L5.06954 12L5.62077 13.0106C5.70217 13.1598 5.70217 13.3402 5.62077 13.4894L5.06954 14.5L5.62077 15.5106C5.70217 15.6598 5.70217 15.8402 5.62077 15.9894L5.34227 16.5H6.79282L8.03861 15.2542C7.41958 14.9142 7 14.2561 7 13.5V10.5C7 9.39543 7.89543 8.5 9 8.5H14.7928L15.7928 7.5ZM13.7928 9.5H9C8.44772 9.5 8 9.94772 8 10.5V13.5C8 13.9876 8.34894 14.3936 8.81071 14.4821L13.7928 9.5Z" fill="#0C172F"/>
				<path d="M16 10.5C16 10.2239 16.2239 10 16.5 10C16.7761 10 17 10.2239 17 10.5V13.5C17 14.6046 16.1046 15.5 15 15.5H11.5C11.2239 15.5 11 15.2761 11 15C11 14.7239 11.2239 14.5 11.5 14.5H15C15.5523 14.5 16 14.0523 16 13.5V10.5Z" fill="#0C172F"/>
				<path d="M9 17C9 17.2761 9.22386 17.5 9.5 17.5H19.5C19.6765 17.5 19.8399 17.407 19.9299 17.2552C20.02 17.1035 20.0234 16.9155 19.9389 16.7606L19.3877 15.75L19.9389 14.7394C20.0204 14.5902 20.0204 14.4098 19.9389 14.2606L19.3877 13.25L19.9389 12.2394C20.0204 12.0902 20.0204 11.9098 19.9389 11.7606L19.3877 10.75L19.9389 9.73943C20.0204 9.59019 20.0204 9.40981 19.9389 9.26057L19.3877 8.25L19.598 7.86443C19.7303 7.622 19.6409 7.31828 19.3985 7.18605C19.1561 7.05382 18.8524 7.14315 18.7201 7.38557L18.3792 8.01057C18.2978 8.15981 18.2978 8.34019 18.3792 8.48943L18.9305 9.5L18.3792 10.5106C18.2978 10.6598 18.2978 10.8402 18.3792 10.9894L18.9305 12L18.3792 13.0106C18.2978 13.1598 18.2978 13.3402 18.3792 13.4894L18.9305 14.5L18.3792 15.5106C18.2978 15.6598 18.2978 15.8402 18.3792 15.9894L18.6577 16.5H9.5C9.22386 16.5 9 16.7239 9 17Z" fill="#0C172F"/>
			</mask>
			<g mask="url(#mask0_2975_983)">
				<rect width="24" height="24" fill="#F05B2B"/>
			</g>
		</svg>);
};
export default TobaccoIcon;
