/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Component } from "react";
import Script from "react-load-script";
import { textMarginSmall } from "../../styles/base/textHelpers";
import RichTextTitle from "../RichTextTitle";
class SaavnWidget extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            errorLoadingScript: false
        };
        this.onLoadScript = () => {
            let scriptLink = this.props.data.getIn(["content", "scriptLink"]);
            window.JioSaavnEmbedWidget.init({
                a: "1",
                q: "1",
                embed_src: `${scriptLink}`,
                partner_id: "insider",
                inline: "1",
                dl: "1"
            });
        };
        this.onError = () => {
            this.setState({ errorLoadingScript: true });
        };
    }
    componentWillUnmount() {
        //The script from https://www.jiosaavn.com/embed/_s/embed.js does
        //var JioSaavnEmbedWidget = JioSaavnEmbedWidget || (excute function to embed widget)
        //so widget appears only for first time. Hence below fix
        window.JioSaavnEmbedWidget = false;
    }
    render() {
        let listenLink = this.props.data.getIn(["content", "listenLink"]);
        return (!this.state.errorLoadingScript && (<React.Fragment>
					<RichTextTitle text="Listen on JioSaavn"/>
					<section css={css(textMarginSmall())} className="text text-left">
						<div id="jiosaavn-widget">
							<div style={{ position: "fixed", top: "100%" }}>
								Listen to the
								<a href={listenLink} target="_blank">
									latest songs
								</a>
								, only on
								<a href="https://www.jiosaavn.com/" target="_blank">
									JioSaavn.com
								</a>
							</div>
							<Script url={`https://www.jiosaavn.com/embed/_s/embed.js?ver=${Date.now()}`} onLoad={this.onLoadScript} onError={this.onError}/>
						</div>
					</section>
				</React.Fragment>));
    }
}
export default SaavnWidget;
