/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Fragment } from "react";
import { IPLMatchEventIds, LSGMatchEventIds } from "../../../helpers/constants";
import theme, { colors } from "../../../styles/common";
import AlcoholIcon from "./icons/AlcoholIcon";
import AudioRecorderIcon from "./icons/AudioRecorderIcon";
import BackpackIcon from "./icons/BackpackIcon";
import BagIcon from "./icons/BagIcon";
import BottleIcon from "./icons/BottleIcon";
import CameraIcon from "./icons/CameraIcon";
import CanIcon from "./icons/CanIcon";
import CoinsIcon from "./icons/CoinsIcon";
import FireIcon from "./icons/FireIcon";
import FlagIcon from "./icons/FlagIcon";
import LaptopIcon from "./icons/LaptopIcon";
import LighterIcon from "./icons/LighterIcon";
import MetalIcon from "./icons/MetalIcon";
import MusicalIcon from "./icons/MusicalIcon";
import NotAllowedIcon from "./icons/NotAllowedIcon";
import OutsideFoodIcon from "./icons/OutsideFoodIcon";
import PetIcon from "./icons/PetIcon";
import PosterIcon from "./icons/PosterIcon";
import PowerBank from "./icons/PowerBank";
import SprayIcon from "./icons/SprayIcon";
import TinIcon from "./icons/TinIcon";
import ToxicIcon from "./icons/ToxicIcon";
import UmbrellaIcon from "./icons/UmbrellaIcon";
import VideoRecorderIcon from "./icons/VideoReorder";
import TobaccoIcon from "./icons/TobaccoIcon";
const primaryItemsList = [
    { icon: <AlcoholIcon />, text: 'Alcohol' },
    { icon: <AudioRecorderIcon />, text: 'Audio Recorder' },
    { icon: <BagIcon />, text: 'Bags' },
    { icon: <BackpackIcon />, text: 'Backpack' },
    { icon: <BottleIcon />, text: 'Bottles' },
    { icon: <CanIcon />, text: 'Cans' },
    { icon: <CameraIcon />, text: 'Camera' },
    { icon: <CoinsIcon />, text: 'Coins' },
    { icon: <FlagIcon />, text: 'Flags (Pole)' },
    { icon: <FireIcon />, text: 'Flammable Goods' },
    { icon: <LaptopIcon />, text: 'Laptops' },
    { icon: <LighterIcon />, text: 'Lighter & Matchbox' },
    { icon: <MetalIcon />, text: 'Metal Containers' },
    { icon: <MusicalIcon />, text: 'Musical Instruments' },
    { icon: <OutsideFoodIcon />, text: 'Outside Food' },
    { icon: <PetIcon />, text: 'Pets & Animals ' },
    { icon: <PosterIcon />, text: 'Posters & Banners' },
    { icon: <PowerBank />, text: 'Power Bank' },
    { icon: <SprayIcon />, text: 'Sprays' },
    { icon: <TinIcon />, text: 'Tins' },
    { icon: <ToxicIcon />, text: 'Toxic' },
    { icon: <UmbrellaIcon />, text: 'Umbrellas' },
    { icon: <VideoRecorderIcon />, text: 'Video Recorder' },
];
const getLSGPrimaryList = (isLSGMatchEvent) => [
    { icon: <CoinsIcon />, text: "Coins" },
    { icon: <TobaccoIcon />, text: "Chewing tobacco and Gutka" },
    ...primaryItemsList.filter(item => (isLSGMatchEvent ? item.text !== "Coins" : true))
];
const secondaryItemList = (isLSGMatchEvent) => [
    "Balloons",
    "Blocks",
    "Communication of match details Statistics / Images",
    "Chewing tobacco and Gutka",
    "Dangerous goods",
    "Eatable (except baby food)",
    "Firecrackers and weapons",
    "Glass containers",
    "Helmets",
    "Illegal or hazardous substances",
    "Laser light/pointer/flashlight",
    "No CAA/NRC Protest Banners",
    "Pens and pencil",
    "Promotional products",
    "Radio",
    "Roller blades/skates",
    "Selfie stick",
    "Sporting Balls",
    "Single Alphabetical Letters",
    "Sharp objects (such as knives, syringes etc.)",
    "T-Shirts",
    "Whistle/horn/loud hailers",
    "Wooden sticks"
].filter(item => (isLSGMatchEvent ? item !== "Chewing tobacco and Gutka" : true));
const ProhibitedListDivCss = `
    padding: 18px;
    border-radius: 6px;
    border: 1px solid ${colors.faintGrey};
    ${theme.mediaQueries["for-mobile"]} {
        padding: 12px;
    }
    `;
const capsuleCss = `
    border: 1px solid ${colors.faintGrey};
    margin-right: 9px;
    margin-top:9px;
    padding: 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    color: ${colors.lightGray};
    display: inline-block;
    white-space: nowrap;
    ${theme.mediaQueries["for-mobile"]} {
        font-size: 12px;
    }
    svg{
        margin-right: 3px;
        height: 24px;
        width: 24px;
        vertical-align: middle;
        ${theme.mediaQueries["for-mobile"]} {
            height: 16px;
            width: 16px;
        }
    }
`;
const secondaryItemListDiv = `
    display:flex;
`;
const secondaryCapsule = `
    display: flex;
    color: ${colors.lightGray};
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 9px;
    margin-right: 9px;
    ${theme.mediaQueries["for-mobile"]} {
        font-size: 12px;
    }
    svg{
        margin-right: 3px;
        height: 24px;
        width: 24px;
        vertical-align: middle;
        ${theme.mediaQueries["for-mobile"]} {
            height: 16px;
            width: 16px;
        }
    }
`;
const headerText = `
    font-size: 18px;
    font-weight: 700;
    color:${colors.lightGray};
    line-height: 150%;
    ${theme.mediaQueries["for-mobile"]} {
        font-size: 14px;
    }
`;
const headerSubText = `
    font-size: 15px;
    color:${colors.lightGray};
    line-height: 150%;
    ${theme.mediaQueries["for-mobile"]} {
        font-size: 12px;
    }
`;
const separatorCss = `
    margin: 12px 0px;
    border: 0.5px solid ${colors.faintGrey};
`;
const textCss = `
    display: inline-grid;
    align-items: center;
`;
const seperator = () => {
    return <div css={css(separatorCss)}></div>;
};
const ProhibitedList = (props) => {
    const { event_id } = props;
    const shouldDisplayProhibitedList = IPLMatchEventIds.includes(event_id);
    const isLSGMatchEvent = LSGMatchEventIds.includes(event_id);
    const secondaryItemListPart1 = secondaryItemList(isLSGMatchEvent).slice(0, secondaryItemList(isLSGMatchEvent).length / 2);
    const secondaryItemListPart2 = secondaryItemList(isLSGMatchEvent).slice(secondaryItemList(isLSGMatchEvent).length / 2);
    return shouldDisplayProhibitedList ? <div css={css(ProhibitedListDivCss)}>
        <div>
            <div css={css(headerText)}>Prohibited Items</div>
            <div css={css(headerSubText)}>The following items are not allowed inside the venue.</div>
        </div>
        {seperator()}
        <div>{(isLSGMatchEvent ? getLSGPrimaryList(isLSGMatchEvent) : primaryItemsList).map(item => {
            return (<span css={css(capsuleCss)}>
                    {item.icon}<span css={css(textCss)}>{item.text}</span>
                </span>);
        })}</div>
        {seperator()}
        <div css={css(secondaryItemListDiv)}>
            <div>{secondaryItemListPart1.map(item => {
            return (<div css={css(secondaryCapsule)}>
                        <div><NotAllowedIcon /></div><span css={css(textCss)}>{item}</span>
                    </div>);
        })}
            </div>
            <div>{secondaryItemListPart2.map(item => {
            return (<div css={css(secondaryCapsule)}>
                        <div><NotAllowedIcon /></div><span css={css(textCss)}>{item}</span>
                    </div>);
        })}
            </div>

        </div>
    </div> : <Fragment />;
};
export default ProhibitedList;
