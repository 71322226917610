import React, { Component } from "react";
import CardList from "./CardList";
import CardListShell from "./CardListShell";
import { fromJS } from "immutable";
class ItemCardList extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            cardData: []
        };
    }
    componentDidMount() {
        Promise.all(this.props.data.getIn(["content", "items"]).map(item => {
            return fetch(`/items/getItemDetailsForClient/${item.getIn(["content", "itemId"])}`, {});
        }))
            .then(responses => {
            return Promise.all(responses.map(response => response.json()));
        })
            .then(cards => {
            this.setState(() => {
                return {
                    cardData: fromJS(cards.map(card => card.data))
                };
            });
        });
    }
    render() {
        return (<div className="card-list-wrapper item-grid">
				<CardList cards={this.state.cardData} emptyMessageComponent={<CardListShell />}/>;
			</div>);
    }
}
ItemCardList.defaultProps = {};
export default ItemCardList;
