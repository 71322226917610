import React from "react";
export default class PriceIcon extends React.Component {
    render() {
        return (<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M2.17223 4.97276L10.4291 0.945641C10.9254 0.703535 11.5241 0.909674 11.7662 1.40606L13.3414 4.63568C13.5835 5.13207 13.3774 5.73074 12.881 5.97285L4.62416 9.99997C4.12777 10.2421 3.5291 10.0359 3.287 9.53954L1.71181 6.30993C1.4697 5.81354 1.67584 5.21487 2.17223 4.97276Z" fill="white" stroke="#0C172F"/>
				<path d="M7.93203 7.34624C8.81569 7.34624 9.53203 6.6299 9.53203 5.74624C9.53203 4.86258 8.81569 4.14624 7.93203 4.14624C7.04838 4.14624 6.33203 4.86258 6.33203 5.74624C6.33203 6.6299 7.04838 7.34624 7.93203 7.34624Z" fill="white" stroke="#0C172F"/>
				<path d="M3 5.9729H12.051C12.8794 5.9729 13.551 6.64447 13.551 7.4729V12.795C13.551 13.6235 12.8794 14.295 12.051 14.295H3C2.17157 14.295 1.5 13.6235 1.5 12.795V7.4729C1.5 6.64447 2.17157 5.9729 3 5.9729Z" fill="white" stroke="#0C172F"/>
				<path d="M12.1137 5.23975H2.93725C1.86904 5.23975 1 6.12964 1 7.2235V13.6968C1 14.7906 1.86904 15.6805 2.93725 15.6805H12.1137C13.1819 15.6805 14.051 14.7906 14.051 13.6968V11.5042V9.41606V7.2235C14.051 6.12964 13.1819 5.23975 12.1137 5.23975ZM12.1449 14.7314H2.90604C2.28339 14.7314 1.77684 14.2203 1.77684 13.592V7.17009C1.77684 6.54183 2.28339 6.03071 2.90604 6.03071H12.1449C12.7676 6.03071 13.2741 6.54183 13.2741 7.17009V8.93093H11.0157C10.2233 8.93093 9.57858 9.58146 9.57858 10.381C9.57858 11.1806 10.2233 11.8312 11.0157 11.8312H13.2741V13.592C13.2741 14.2203 12.7676 14.7314 12.1449 14.7314ZM13.2741 11.0929H11.0768C10.7463 11.0929 10.4775 10.8091 10.4775 10.4601C10.4775 10.1112 10.7463 9.82736 11.0768 9.82736H13.2741V11.0929Z" fill="#0C172F" stroke="#0C172F" strokeWidth="0.2"/>
			</svg>);
    }
}
