import React from "react";
import { withRouter } from "react-router";
const NoResults = props => {
    const searchParams = new URLSearchParams(props.location.search);
    const platformParams = searchParams.get("platform") ? `?platform=${searchParams.get("platform")}` : "";
    const replacedText = props.text.includes("home")
        ? props.text.replace("home", `<a href='/${platformParams}'>home</a>`)
        : props.text;
    return (<div className="insider-no-data">
			<img height="142" width="240" className="no-data-image" src="/assets/images/extras/no-results.svg"/>
			<span className="title-desc" dangerouslySetInnerHTML={{ __html: replacedText }}/>
			{props.children}
		</div>);
};
export default withRouter(NoResults);
