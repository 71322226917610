/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment } from "react";
import classNames from "classnames";
import { mediaFeatures } from "../styles/common";
import { standaloneVisual, visualCard } from "../styles/modules/headerCards";
const headerVisualStyle = `
	border-radius: 8px;
	overflow: hidden;
    @media screen and ${mediaFeatures["for-mobile"]} {
		border-radius: 0;
	}
	${visualCard}
`;
const SummaryCardVisual = (props) => {
    return (<Fragment>
			<div className={classNames(props.shellClass)} css={css([headerVisualStyle, props.standalone && standaloneVisual])}>
				{props.headerImageOrVideo}
			</div>
			{props.monumentsBanner}
		</Fragment>);
};
SummaryCardVisual.defaultProps = {
    isSPA: true,
    standalone: false,
    monumentsBanner: <Fragment />
};
export default SummaryCardVisual;
