import PropTypes from "prop-types";
import React from "react";
import CardList from "./CardList";
import CardListTitle from "./CardListTitle";
import CardListHeader from "./CardListHeader";
import CardListWrapper from "./CardListWrapper";
const CardsWithTitle = ({ title, cards, emptyMessageComponent, cardPrefix, itemType }) => {
    return (<CardListWrapper essentialClass={cardPrefix ? `${cardPrefix}-grid` : undefined}>
			<CardListHeader>
				<CardListTitle title={title}/>
			</CardListHeader>
			<CardList cards={cards} cardPrefix={cardPrefix} itemType={itemType} emptyMessageComponent={emptyMessageComponent}/>
		</CardListWrapper>);
};
CardsWithTitle.propTypes = {
    title: PropTypes.string.isRequired,
    cards: PropTypes.object.isRequired,
    emptyMessageComponent: PropTypes.node
};
export default CardsWithTitle;
