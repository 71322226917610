/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes, { bool } from "prop-types";
import React from "react";
import { List } from "immutable";
import CarouselWrapper from "./CarouselWrapper";
import CarouselHeader from "./CarouselHeader";
import CarouselTitle from "./CarouselTitle";
import Carousel from "../components/Carousel";
import DataCard from "../containers/DataCardWithSubscription";
import classNames from "classnames";
import { InsiderLink } from "./InsiderLink";
import { colors, mediaFeatures } from "../styles/common";
const dummyDataForShell = new List().setSize(4);
const carouselClass = {
    carousel: "card-carousel",
    "cell-carousel": "cell-carousel",
    "carousel-inverted": "card-carousel inverted",
    "cell-carousel-inverted": "cell-carousel inverted"
};
const carouselItemCount = {
    carousel: 3,
    "cell-carousel": 4,
    "carousel-inverted": 3,
    "cell-carousel-inverted": 4
};
const carouselExtraLinkBtn = `
	background-color: ${colors["white"]};
	color: black;
	padding: 5px 10px;
	text-decoration: none;
	border: 1px solid ${colors["gray"]};
	border-radius 5px;
	@media screen and ${mediaFeatures["for-mobile"]} {
        max-width: 100px;
    }
`;
const DataCardCarousel = props => {
    const childData = props.cards && (props.cards.size || props.cards.length) ? props.cards : dummyDataForShell;
    const micrositePrimaryColor = props.micrositePrimaryColor ? props.micrositePrimaryColor : ""; //to set header of carousel in microsite's primary color, when called from there
    const { cardClickHandler, addToCt, tags } = props;
    const DataCards = childData && childData.map((card, index) => {
        return (<li className="carousel-item carousel-item-right" key={`carousel-card-${index}`} onClick={(event) => cardClickHandler(index + 1, card.get("name"), card.get("slug"), event)}>
				<DataCard cardPrefix={props.cardPrefix} cardData={card} index={index} addToCt={addToCt} tags={tags}/>
			</li>);
    });
    const showCarouselExtraButton = !!props.carouselExtraLink;
    if (props.showTitle === "false") {
        return (<CarouselWrapper className={classNames("card-list-wrapper", "no-title-wrapper", carouselClass[props.carouselType])} dataRef={props.dataRef}>
				<Carousel numCarouselItems={carouselItemCount[props.carouselType]} listType="card">
					{DataCards}
				</Carousel>
			</CarouselWrapper>);
    }
    else {
        return (<CarouselWrapper className={classNames("card-list-wrapper", carouselClass[props.carouselType])} dataRef={props.dataRef}>
				<CarouselHeader className={props.headerClass} css={css(props.carouselHeaderCSS)}>
					<CarouselTitle title={props.title} micrositePrimaryColor={micrositePrimaryColor}/>
					{showCarouselExtraButton && <InsiderLink href={props.carouselExtraLink.link || "/"} onClick={props.carouselExtraLink.clickHandler}>
						<button css={css(carouselExtraLinkBtn)} data-ref={props.carouselExtraLink.dataRef}>
							{props.carouselExtraLink.title || "View more Events"}
						</button>
					</InsiderLink>}
				</CarouselHeader>
				<Carousel numCarouselItems={carouselItemCount[props.carouselType]} listType="card">
					{DataCards}
				</Carousel>
			</CarouselWrapper>);
    }
};
DataCardCarousel.propTypes = {
    title: PropTypes.string,
    cards: PropTypes.object,
    className: PropTypes.string,
    carouselType: PropTypes.string,
    addToCt: PropTypes.bool,
    tags: PropTypes.string,
};
DataCardCarousel.defaultProps = {
    title: "",
    cards: dummyDataForShell,
    carouselType: "carousel",
    cardClickHandler: () => { },
    tags: "",
    addToCt: false
};
export default DataCardCarousel;
