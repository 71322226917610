import React from "react";
export default class PhysicalVenueIcon extends React.Component {
    render() {
        return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0)">
					<circle cx="8" cy="6" r="2.4" stroke="#0C172F" strokeWidth="1.2"/>
					<path d="M10.3321 1.50978C11.6159 2.15878 12.3811 2.91313 12.7821 3.72799C13.1818 4.54032 13.2556 5.48641 13.0179 6.59004C12.5337 8.83735 10.7951 11.5732 8.29618 14.6878C8.14421 14.8773 7.85561 14.8773 7.70364 14.6878C5.20507 11.5733 3.46656 8.83741 2.98235 6.5901C2.74455 5.48646 2.81836 4.54036 3.21795 3.72804C3.61878 2.91319 4.38385 2.15886 5.66738 1.50987C7.11906 0.775855 8.88039 0.77585 10.3321 1.50978Z" stroke="#0C172F" strokeWidth="1.2"/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="16" height="16" fill="white"/>
					</clipPath>
				</defs>
			</svg>);
    }
}
