import PropTypes from "prop-types";
import React from "react";
const CardListTitle = props => {
    return (<div className="title-container">
			<span className={props.titleClassName}>{props.title}</span>
		</div>);
};
CardListTitle.propTypes = {
    title: PropTypes.string,
    titleClassName: PropTypes.string
};
CardListTitle.defaultProps = {
    titleClassName: "title-main"
};
export default CardListTitle;
