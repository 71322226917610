import React from "react";
const PosterIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M29 10C27.3415 10 25.8716 10.8075 24.962 12.0508C24.6459 12.0172 24.325 12 24 12C19.0294 12 15 16.0294 15 21C15 25.9706 19.0294 30 24 30C28.9706 30 33 25.9706 33 21C33 20.11 32.8708 19.2502 32.6302 18.4383C33.4792 17.5422 34 16.3319 34 15C34 12.2386 31.7614 10 29 10ZM29 12C28.3303 12 27.7119 12.2194 27.2127 12.5903C29.0875 13.307 30.6613 14.6326 31.6922 16.3252C31.8893 15.9256 32 15.4757 32 15C32 13.3431 30.6569 12 29 12ZM31 21C31 20.5345 30.9546 20.0795 30.8679 19.6394C30.2908 19.872 29.6603 20 29 20C26.2386 20 24 17.7614 24 15C24 14.6578 24.0344 14.3236 24.0999 14.0007C24.0666 14.0002 24.0333 14 24 14C20.134 14 17 17.134 17 21C17 24.866 20.134 28 24 28C27.866 28 31 24.866 31 21ZM29 18C29.4272 18 29.8335 17.9107 30.2013 17.7498C29.3452 16.1198 27.8653 14.8687 26.0788 14.3139C26.0273 14.5342 26 14.7639 26 15C26 16.6569 27.3431 18 29 18Z" fill="#F05B2B"/>
        <path d="M20 37C20 36.4477 20.4477 36 21 36H28C28.5523 36 29 36.4477 29 37C29 37.5523 28.5523 38 28 38H21C20.4477 38 20 37.5523 20 37Z" fill="#F05B2B"/>
        <path d="M17 32C16.4477 32 16 32.4477 16 33C16 33.5523 16.4477 34 17 34L31 34C31.5523 34 32 33.5523 32 33C32 32.4477 31.5523 32 31 32L17 32Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 6C8.44772 6 8 6.44772 8 7V41C8 41.5523 8.44772 42 9 42H39C39.5523 42 40 41.5523 40 41V7C40 6.44772 39.5523 6 39 6H9ZM10 40V8H38V40H10Z" fill="#F05B2B"/>
    </svg>;
};
export default PosterIcon;
