import React from "react";
import classNames from "classnames";
import { LinkWithH5Support } from "./LinkWithH5Support";
const constructTwitterWebIntent = ({ text, url }) => {
    const tweetString = `text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}&via=paytminsider`;
    return `https://twitter.com/intent/tweet?${tweetString}`;
};
const TwitterShareButton = (props) => (<LinkWithH5Support className={classNames("social-button social-twitter", {
        large: props.large,
        block: props.block
    })} href={constructTwitterWebIntent({
        text: props.text,
        url: props.url
    })} onClick={() => {
        ga("send", {
            hitType: "event",
            eventCategory: "TwitterShare",
            eventAction: props.position,
            eventLabel: window.location.pathname
        });
        props.sendEdpShareFallbackEvent("Twitter", props.url, props.name);
    }} target="_blank">
		Share this on Twitter
	</LinkWithH5Support>);
TwitterShareButton.defaultProps = {
    position: "default",
    large: false,
    block: false
};
export default TwitterShareButton;
