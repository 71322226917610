import PropTypes from "prop-types";
import React from "react";
import CardList from "./CardList";
import CardListWrapper from "./CardListWrapper";
const CardListWithoutTitle = ({ allowFavouriting, cardPrefix, cards, emptyMessageComponent, itemType, ssrImageCount }) => {
    return (<CardListWrapper essentialClass={cardPrefix ? `${cardPrefix}-grid` : undefined} className="no-title-wrapper">
			<CardList allowFavouriting={allowFavouriting} cards={cards} cardPrefix={cardPrefix} itemType={itemType} emptyMessageComponent={emptyMessageComponent} ssrImageCount={ssrImageCount}/>
		</CardListWrapper>);
};
CardListWithoutTitle.propTypes = {
    cards: PropTypes.object.isRequired,
    emptyMessageComponent: PropTypes.node,
    ssrImageCount: PropTypes.number
};
export default CardListWithoutTitle;
