import React from "react";
const CameraIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 24C32 25.1046 32.8954 26 34 26H36C37.1046 26 38 25.1046 38 24V22C38 20.8954 37.1046 20 36 20H34C32.8954 20 32 20.8954 32 22V24ZM36 24H34V22H36V24Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 24C22.3431 24 21 25.3431 21 27C21 28.6569 22.3431 30 24 30C25.6569 30 27 28.6569 27 27C27 25.3431 25.6569 24 24 24ZM23 27C23 26.4477 23.4477 26 24 26C24.5523 26 25 26.4477 25 27C25 27.5523 24.5523 28 24 28C23.4477 28 23 27.5523 23 27Z" fill="#F05B2B"/>
        <path d="M24 22C23.3351 22 22.7029 22.1293 22.1252 22.3631C21.6133 22.5703 21.0303 22.3233 20.8231 21.8114C20.6158 21.2994 20.8629 20.7164 21.3748 20.5092C22.1868 20.1805 23.0736 20 24 20C27.866 20 31 23.134 31 27C31 30.866 27.866 34 24 34C20.134 34 17 30.866 17 27C17 25.3456 17.5752 23.8227 18.5357 22.6245C18.8812 22.1936 19.5105 22.1243 19.9414 22.4698C20.3724 22.8152 20.4417 23.4446 20.0962 23.8755C19.4099 24.7317 19 25.8166 19 27C19 29.7614 21.2386 32 24 32C26.7614 32 29 29.7614 29 27C29 24.2386 26.7614 22 24 22Z" fill="#F05B2B"/>
        <path d="M10 33C10 33.5523 10.4477 34 11 34C11.5523 34 12 33.5523 12 33L12 26C12 25.4477 11.5523 25 11 25C10.4477 25 10 25.4477 10 26L10 33Z" fill="#F05B2B"/>
        <path d="M28 15C28 15.5523 27.5523 16 27 16L21 16C20.4477 16 20 15.5523 20 15C20 14.4477 20.4477 14 21 14L27 14C27.5523 14 28 14.4477 28 15Z" fill="#F05B2B"/>
        <path d="M10 22C10 22.5523 10.4477 23 11 23C11.5523 23 12 22.5523 12 22V21C12 20.4477 11.5523 20 11 20C10.4477 20 10 20.4477 10 21V22Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16H11C8.23858 16 6 18.2386 6 21V33C6 35.7614 8.23858 38 11 38H37C39.7614 38 42 35.7614 42 33V21C42 18.2386 39.7614 16 37 16H32V15C32 12.2386 29.7614 10 27 10H21C18.2386 10 16 12.2386 16 15V16ZM18 17C18 17.5523 17.5523 18 17 18H11C9.34315 18 8 19.3431 8 21V33C8 34.6569 9.34315 36 11 36H37C38.6569 36 40 34.6569 40 33V21C40 19.3431 38.6569 18 37 18H31C30.4477 18 30 17.5523 30 17V15C30 13.3431 28.6569 12 27 12H21C19.3431 12 18 13.3431 18 15V17Z" fill="#F05B2B"/>
    </svg>;
};
export default CameraIcon;
