/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Map } from "immutable";
import ContentComponents from "../ContentComponents";
import RichTextTitle from "../RichTextTitle";
import VenueCard from "../VenueCard";
import PromoBanner from "../PromoBanner";
import InsiderPassLink from "../InsiderPassLink";
import SeatMapImage from "../SeatMapImage";
import EventSafetyMeasures from "./EventSafetyMeasures";
import EDPShare from "../EDPShare";
import ImageList from "../ImageList";
import RichTextDisplay from "../RichTextDisplay";
import VideoCard from "../VideoCard";
import SalientFeatures from "../SalientFeatures";
// components
import { Accordion } from "../Accordion";
import { AccordionPanel } from "../AccordionPanel";
import { AccordionTitle } from "../AccordionTitle";
import theme, { colors, mediaFeatures } from "../../styles/common";
import { Fragment } from "react";
import InsiderLoadable from "../InsiderLoadable";
import InsiderLinkContext from "../../contexts/InsiderLinkContext";
import ProhibitedList from "./ProhibitedList";
const EDPReportSection = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "EDPReportSection" */ "../EDPReportSection")
});
const mobileDisplay = `
	display:none;
	${theme.mediaQueries["for-mobile-and-tablet"]} {
		display: block;
	};
	position: sticky;
    bottom: 65px;
`;
const EventSummaryCardDetailWrap = `
	display: none;
	${theme.mediaQueries["for-mobile"]} {
		display: block;
	}
	${theme.mediaQueries["for-small-tablet-only"]} {
		display: block;
	}
`;
// have to do this only once
const metadataForEventText = new Map({
    metadata: new Map({
        align: "left"
    })
});
const EventDescriptionStyle = `
	& span {
		font-size: 19px;
		margin-bottom: 30px;
		margin-top: 30px;
		font-weight: bold;
		color: ${colors.descriptionColor};
		display: block;
		@media screen and ${mediaFeatures["for-large-tablet"]} {
			font-size: 21px;
		}
	}
`;
const hideOnDesktop = `
	@media screen and ${mediaFeatures["for-pc"]} {
		display: none;
	}
`;
const blockIOSTitle = `
	font-size:16px;
	font-weight: bold;
`;
const blockIOSDesc = `
	font-size:16px;
	margin-top:8px;
`;
const blockIOSDiv = `    
	padding: 18px 9px;
	border-radius: 8px;
	width: 88vw;
	background: lightyellow;`;
const EventTerms = ({ terms }) => {
    if (!terms) {
        return null;
    }
    let termsMarkup = terms.reduce((a, t) => a + `<li>${t}</li>`, "");
    termsMarkup = `<ul>${termsMarkup}</ul>`;
    const metadataForTermsPanel = new Map({
        metadata: new Map({
            align: "left"
        })
    });
    return (<Accordion key="event-accordion-terms" className="text">
			<AccordionTitle>
				<span css={{
            fontSize: 19,
            fontWeight: "bold",
            color: colors.descriptionColor,
            [theme.mediaQueries["for-large-tablet"]]: {
                fontSize: 25
            }
        }}>
					<span className="icon-fa icon-plus"/>
					Terms & Conditions
				</span>
			</AccordionTitle>
			<AccordionPanel>
				<RichTextDisplay metaClass="custom-widget-content" data={metadataForTermsPanel.set("content", termsMarkup)}/>
			</AccordionPanel>
		</Accordion>);
};
/**
 * Check if Youtube embed link
 * @param {String} embedHtml
 * @returns {boolean}
 */
const isYoutubeEmbed = (embedHtml) => {
    return /src=".+youtube/.test(embedHtml);
};
/**
 * Extract Youtube Video src (https://regex101.com/r/zYtlTe/1)
 * @param {String} embedHtml
 * @returns {null | String}
 */
const getVideoURL = (embedHtml) => {
    const match = /src="([^"]+)"/g.exec(embedHtml);
    return match != null ? match[1] : null;
};
const EventCustomHtml = (props) => {
    if (isYoutubeEmbed(props.sectionData.get("html")) && getVideoURL(props.sectionData.get("html")) !== null) {
        return (<VideoCard data={new Map({
                content: new Map({
                    embedsrc: getVideoURL(props.sectionData.get("html")),
                    caption: ""
                })
            })}/>);
    }
    return (<RichTextDisplay cssStyle={`margin-left: 0 !important;`} data={metadataForEventText.set("content", `${props.sectionData.get("html")}`)}/>);
};
const EventLineUp = props => {
    if (props.lineupData.size > 0) {
        const lineupCarouselItems = new Map({
            content: new Map({
                images: props.lineupData.map(v => {
                    return new Map({
                        src: v.get("imgUrl"),
                        caption: v.get("name"),
                        height: 240,
                        width: 360
                    });
                }),
                imageListTitle: ""
            }),
            metadata: new Map({
                display: "carousel-limited",
                numCarouselItems: 2
            })
        });
        return <ImageList data={lineupCarouselItems} carouselCSS={`margin-left: 0px !important;`}/>;
    }
    return null;
};
const EventImages = (props) => {
    if (props.images && props.images.size) {
        return (<ImageList data={Map({
                content: Map({
                    images: props.images.map(imageObj => Map({
                        src: imageObj.get("large"),
                        height: 800,
                        width: 800
                    })),
                    imageListTitle: ""
                }),
                metadata: new Map({
                    display: "carousel-limited",
                    numCarouselItems: 2
                })
            })}/>);
    }
    return null;
};
/**
 * Cleans up the event description
 * Converts \n to <p> tags
 * Adds the About header
 * @param {string} description
 * @return {React.Element}
 */
const EventDescription = ({ description }) => {
    const sanitizedDescription = description
        .split("\n")
        .filter(v => v) // remove empty strings
        .map(v => `<p>${v}</p>`)
        .join("<br/>");
    return (<Fragment>
			<RichTextDisplay data={metadataForEventText.set("content", "<span>About the Event</span>")} cssStyle={css([EventDescriptionStyle, hideOnDesktop])}/>
			<RichTextDisplay data={metadataForEventText.set("content", sanitizedDescription)} cssStyle={css(EventDescriptionStyle)}/>
		</Fragment>);
};
const EventGuide = props => {
    const { eventData, isSafetyMeasuresPresent, shouldSkipShareSection, isFromApp, promoData, summaryCardDetail, shouldBlockiOSPurchase, type, reportEventHandler, queryParams, searchParams, shouldSkipReportSection, shouldShowPrerequisitiesSection } = props;
    return (<div>
			<div css={css(EventSummaryCardDetailWrap)}>
				{summaryCardDetail}
				{props.seperator}
			</div>

			{shouldBlockiOSPurchase && (<div css={[css(blockIOSDiv), css(hideOnDesktop)]}>
					<p css={css(blockIOSTitle)}>You cannot purchase this on the app.</p>
					<p css={css(blockIOSDesc)}>
						Sorry, we know it sucks but head to your browser and get them. If you've already purchased it elsewhere,
						head over to the ticket section to view the details.
					</p>
				</div>)}
			{eventData.get("extra_info") && eventData.get("extra_info").size > 0 && (<section className="text" css={css(`
							margin: 30px auto;
							@media screen and ${mediaFeatures["for-pc"]} {
								display: none
							}
						`)}>
					<SalientFeatures salient_features={eventData.get("extra_info")} venue={eventData.getIn(["venue", "name"]) || eventData.get("venue_name")} type={type}/>
					{props.seperator}
				</section>)}
			
			{/* mobile share */}
			<div css={{
            marginTop: 30,
            [theme.mediaQueries["for-pc"]]: {
                display: "none"
            }
        }}>
				<div css={{
            marginTop: 30,
            [theme.mediaQueries["for-tablet"]]: {
                display: "none"
            }
        }}>
					{isSafetyMeasuresPresent && <EventSafetyMeasures safety_measures={eventData.get("safety_measures")}/>}
				</div>
				{!shouldSkipShareSection && <EDPShare showShareButtons={props.showShareButtons} seperator={props.seperator}/>}
			</div>

			{isSafetyMeasuresPresent && (<section className={"text text-left"} css={css([
                {
                    [theme.mediaQueries["for-mobile"]]: {
                        display: "none"
                    }
                }
            ])}>
					<EventSafetyMeasures safety_measures={eventData.get("safety_measures")}/>
				</section>)}

			<EventDescription description={eventData.get("description", "")}/>

			{props.seperator}
			{eventData.has("_id") && eventData.get("_id") === "5d5be5d976964c0008123c55" && (<SeatMapImage imageURL="https://media.insider.in/image/upload/c_crop,g_custom/v1570705545/g4rjzh81yvw6enxpoyva.png" redirectURL="/event/oneplus-music-festival-nov16-2019/buy"/>)}
			{promoData && <PromoBanner toLink={promoData.articleLink} imageSrc={promoData.imageLink}/>}
			{promoData && props.seperator}
			{eventData
            .get("custom_html")
            .map((v, i) => [
            <RichTextTitle key={`rich-text-title-${i}`} text={v.get("title")}/>,
            <EventCustomHtml key={`custom-html-${i}`} sectionData={v}/>,
            props.seperator
        ])
            .flatten()}
			{eventData.has("lineup") && eventData.get("lineup").size > 0 && <RichTextTitle text="Line-up"/>}
			<EventLineUp lineupData={eventData.get("lineup")}/>
			{eventData.has("lineup") && eventData.get("lineup").size > 0 && props.seperator}
			{eventData.has("images") && eventData.get("images").size > 0 && <RichTextTitle text="Gallery"/>}
			<EventImages images={eventData.get("images")}/>
			{eventData.has("images") && eventData.get("images").size > 0 && props.seperator}
			<InsiderLinkContext.Provider value={{ searchParams: searchParams }}>
				<ContentComponents cssStyle={`margin-left: 0 !important;`} components={props.contentComponentsData}/>
			</InsiderLinkContext.Provider>
			{props.contentComponentsData.size > 0 && props.seperator}
			<ProhibitedList event_id={eventData.get('_id')}/>
			<VenueCard name={eventData.getIn(["venue", "name"])} latitude={eventData.getIn(["venue", "id", "geolocation", "latitude"])} longitude={eventData.getIn(["venue", "id", "geolocation", "longitude"])} address={eventData.getIn(["venue", "id", "address"])} isFromApp={isFromApp}/>
			<EventTerms terms={eventData.get("terms")}/>
			
			{shouldShowPrerequisitiesSection && props.prerequisites && props.prerequisites.length > 0 && (<div css={css(mobileDisplay)}>
					<InsiderPassLink prerequisites={props.prerequisites} isFromApp={isFromApp} event_id={eventData.get('_id')}/>
				</div>)}
			{!shouldSkipReportSection && (<div css={css(hideOnDesktop)}>
					<EDPReportSection reportEventHandler={reportEventHandler} isFromApp={isFromApp} queryParams={queryParams}/>
				</div>)}
		</div>);
};
export default EventGuide;
