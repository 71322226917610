/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ActionButton from "./ActionButton";
import { imageCacheLink, generateEventBuyLink, generateEventLink } from "../helpers/linkGenerators";
import Image from "./Image";
import { stdPadding } from "../styles/common";
import { eventCardDate, eventCardName, eventCardPrice, eventCardPriceFree, eventCardVenue, largeEventCardNameString, largeEventCardVenuePricing, largeEventCardVisual } from "../styles/cardStyles";
import { capitalizeFirstLetter } from "../helpers/constants";
const styles = {
    border: "1px solid #d0d0d0",
    borderTop: "none"
};
const eventNameStyles = {
    marginTop: stdPadding
};
const LargeEventCard = (props) => {
    var _a, _b;
    const isFromApp = props.platform !== undefined;
    const isFree = ((_b = (_a = props === null || props === void 0 ? void 0 : props.price_display_string) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '') === "free";
    const actionButtonLink = isFromApp ? generateEventLink(props.slug, isFromApp) : generateEventBuyLink(props.slug);
    return (<section className="event-embed">
			<Image src={imageCacheLink(props.horizontal_cover_image)} alt={props.name} css={css(largeEventCardVisual)}/>
			<div css={styles}>
				<div className="event-detail">
					<div css={css([eventNameStyles, eventCardName])}>
						<span css={css(largeEventCardNameString)}>{props.name}</span>
					</div>
					<span css={css(eventCardDate)}>{props.date}</span>
					<span css={css([eventCardVenue, largeEventCardVenuePricing])}>{props.venue_name}{(props.type === "physical" && props.city) ? `, ${capitalizeFirstLetter(props.city)}` : ""}</span>
					<span css={css([eventCardPrice, largeEventCardVenuePricing, isFree && eventCardPriceFree])}>
						{props.price_display_string}
					</span>
					<ActionButton text={props.action_button === "BUY" ? "BUY NOW" : props.action_button} href={actionButtonLink} disabled={props.action_button_disabled} htmlCaption={props.action_button_helper_text} hasItemEventActionModifier={true}/>
				</div>
			</div>
		</section>);
};
export default LargeEventCard;
