import React from "react";
import classNames from "classnames";
import { LinkWithH5Support } from "./LinkWithH5Support";
const constructWhatsAppWebIntent = ({ text, url }) => {
    const shareString = `text=${encodeURIComponent(text + " " + url)}`;
    return `whatsapp://send?${shareString}`;
};
const WhatsappShareButton = (props) => (<LinkWithH5Support className={classNames("social-button social-whatsapp", {
        large: props.large,
        block: props.block
    })} href={constructWhatsAppWebIntent({
        text: props.text,
        url: props.url
    })} onClick={() => {
        ga("send", {
            hitType: "event",
            eventCategory: "WhatsappShare",
            eventAction: props.position,
            eventLabel: window.location.pathname
        });
        props.sendEdpShareFallbackEvent("Whatsapp", props.url, props.name);
    }} target="_blank">
		Share this on Whatsapp
	</LinkWithH5Support>);
WhatsappShareButton.defaultProps = {
    position: "default",
    large: false,
    block: false
};
export default WhatsappShareButton;
