import React from "react";
export default class DateIcon extends React.Component {
    render() {
        return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M2.6 2.35278H13.6C14.4837 2.35278 15.2 3.02699 15.2 3.85867V14.2116C15.2 15.0433 14.4837 15.7175 13.6 15.7175H2.6C1.71634 15.7175 1 15.0433 1 14.2116V3.85867C1 3.02699 1.71634 2.35278 2.6 2.35278ZM13.6 3.4822H2.6C2.37909 3.4822 2.2 3.65075 2.2 3.85867V14.2116C2.2 14.4195 2.37909 14.5881 2.6 14.5881H13.6C13.8209 14.5881 14 14.4195 14 14.2116V3.85867C14 3.65075 13.8209 3.4822 13.6 3.4822Z" fill="#0C172F"/>
				<rect x="4" y="1.41162" width="1" height="2.82353" rx="0.5" fill="#0C172F"/>
				<rect x="11" y="1.41162" width="1" height="2.82353" rx="0.5" fill="#0C172F"/>
				<path d="M15.0984 6.02344V7.15285H1.89844V6.02344H15.0984Z" fill="#0C172F"/>
			</svg>);
    }
}
