/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import { Map } from "immutable";
import ImageListWithSubscription from "../containers/ImageListWithSubscription";
import RichTextDisplay from "./RichTextDisplay";
import VideoCard from "./VideoCard";
import LargeEventCard from "../containers/LargeEventCardWithSubscription";
import DataCardList from "../containers/DataCardListContainerWithFetch";
import ItemCard from "../containers/ItemCardWithSubscription";
import ItemCardList from "./ItemCardList";
import LockedComponent from "./LockedComponent";
import AsyncSubscribeActionPanel from "./AsyncSubscribeActionPanel";
import SaavnWidget from "./SaavnWidget";
const TYPE_TO_COMPONENT_MAP = new Map({
    image: ImageListWithSubscription,
    text: RichTextDisplay,
    video: VideoCard,
    eventcard: LargeEventCard,
    eventcardlist: DataCardList,
    itemcard: ItemCard,
    itemcardlist: ItemCardList,
    saavnWidget: SaavnWidget
});
const ContentComponents = ({ slug, userInfo, components, showSubscribe, subscribePosition, cssStyle, modelsToFetchForTags }) => {
    // render in order
    if (components === undefined || components.size === 0) {
        return null;
    }
    const childRenderComponents = components
        .filter(c => TYPE_TO_COMPONENT_MAP.has(c.get("type", ""))) // keep only components whose type we support
        .map((componentData, index) => {
        const componentType = componentData.get("type");
        const RenderComponent = componentData.get("isLocked") && !userInfo.size ? LockedComponent : TYPE_TO_COMPONENT_MAP.get(componentType);
        return <RenderComponent cssStyle={cssStyle} key={`content-component-${index}`} data={componentData} slug={slug} modelsToFetchForTags={modelsToFetchForTags}/>;
    })
        .update(list => {
        if (showSubscribe) {
            const subscriptionComponent = <AsyncSubscribeActionPanel key="content-injected-subscribe"/>;
            return list.insert(subscribePosition, subscriptionComponent);
        }
        return list;
    });
    return <div className="component-list">{childRenderComponents}</div>;
};
ContentComponents.defaultProps = {
    showSubscribe: false,
    subscribePosition: 0
};
ContentComponents.propTypes = {
    components: PropTypes.object,
    subscribePosition: PropTypes.number.isRequired,
    showSubscribe: PropTypes.bool.isRequired
};
export default ContentComponents;
