import React, { useEffect, useState } from "react";
import classNames from "classnames";
// components
import FilterResultsEmpty from "./FilterResultsEmpty";
// containers
import DataCard from "../containers/DataCardWithSubscription";
import EventCard from "../containers/EventCardWithSubscription";
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";
import CardListLoader from "./CardListLoader";
import { useThrottle } from "@react-hook/throttle";
const numCardsToRender = 6;
const renderCard = (cardData, itemType, idx, section, allowFavouriting, cardPrefix, ssrImageCount) => {
    if (cardData === undefined) {
        return (<li className={classNames("card-list-item", itemType)} key={`no-data-card-list-${idx}-${section}`}>
				<EventCard cardData={cardData}/>
			</li>);
    }
    return (<li className={classNames("card-list-item", itemType)} key={`${idx}-${cardData.get("name")}`}>
			<DataCard allowFavouriting={allowFavouriting} cardData={cardData} cardPrefix={cardPrefix} lazy={idx > ssrImageCount}/>
		</li>);
};
const renderCards = (allowFavouriting, cardPrefix, cards, className, itemType, section, ssrImageCount) => {
    const _className = className !== undefined ? className : "";
    const [currentPage, setCurrentPage] = useThrottle(1, 1);
    const lastPage = Math.ceil(cards.size / numCardsToRender);
    const [hasMore, setHasMore] = useState(currentPage < lastPage);
    useEffect(() => {
        setHasMore(currentPage < lastPage);
    }, [currentPage, lastPage]);
    const isSearchResult = cardPrefix === "search";
    const loadFunc = (page) => {
        if (page > lastPage) {
            // Extra guard check
            setHasMore(false);
            return;
        }
        setCurrentPage(page);
    };
    if (isSearchResult) {
        // Search results have "Load more" logic already through algolia
        return (<ul className={classNames({ "card-list": true, [`${_className}`]: true })}>
				{cards.map((cardData, idx) => renderCard(cardData, itemType, idx, section, allowFavouriting, cardPrefix, ssrImageCount))}
			</ul>);
    }
    // For other parts, use the Infinite Scroll
    return (<ul className={classNames({ "card-list": true, [`${_className}`]: true })}>
			<InfiniteScroll pageStart={1} loadMore={loadFunc} hasMore={hasMore} loader={<CardListLoader itemType={itemType} section={section}/>} threshold={800}>
				{cards
            .take(currentPage * numCardsToRender)
            .map((cardData, idx) => renderCard(cardData, itemType, idx, section, allowFavouriting, cardPrefix, ssrImageCount))}
			</InfiniteScroll>
		</ul>);
};
const CardList = ({ allowFavouriting, cardPrefix, cards, className, emptyMessageComponent, itemType, section, ssrImageCount }) => {
    return (<div>
			{cards.size > 0
            ? renderCards(allowFavouriting, cardPrefix, cards, className, itemType, section, ssrImageCount)
            : emptyMessageComponent}
		</div>);
};
CardList.defaultProps = {
    emptyMessageComponent: <FilterResultsEmpty />
};
export default CardList;
