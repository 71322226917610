import React from "react";
import { Map } from "immutable";
import DataCardCarousel from "../components/DataCardCarousel";
import CardListWithTitle from "../components/CardListWithTitle";
import CardListWithoutTitle from "../components/CardListWithoutTitle";
import CardListShell from "../components/CardListShell";
import Error from "../components/ErrorComponent";
import Loader from "../components/Loader";
import { fetchAPIData } from "../helpers/apiClient";
import { fetchableModelsForTags } from "../helpers/constants";
import CardListEmpty from "../components/CardListEmpty";
import { sortEventByTagCarousalPosition } from "../helpers/misc";
const normalizeDataToCommonSchema = cardData => {
    const model = cardData.get("model");
    const type = cardData.get("type");
    if (model === "article") {
        return Map({
            _id: cardData.get("_id"),
            category_id: cardData.get("category_id"),
            horizontal_cover_image: cardData.get("horizontal_cover_image"),
            model: model,
            slug: cardData.get("slug"),
            title: cardData.get("title")
        });
    }
    if (type === "event") {
        return Map({
            _id: cardData.get("_id"),
            action_button_text: cardData.get("action_button_text"),
            category_id: cardData.get("category_id"),
            event_state: cardData.get("event_state"),
            horizontal_cover_image: cardData.get("horizontal_cover_image"),
            is_rsvp: cardData.get("is_rsvp"),
            model: type,
            name: cardData.get("name"),
            price_display_string: cardData.get("price_display_string"),
            slug: cardData.get("slug"),
            venue_date_string: cardData.getIn(["events", 0, "venues", 0, "date_string"]),
            venue_name: cardData.getIn(["events", 0, "venues", 0, "name"]),
            city: cardData.get("city"),
            event_type: cardData.get("event_type")
        });
    }
};
const defaultModelsToFetch = [
    fetchableModelsForTags.article,
    fetchableModelsForTags.event
];
/**
* Returns list tags url with models and tags as query params
* @example: /tag/list?tags=sahil-shah&models=event
*/
const getListTagURL = (tags, modelsToFetch) => {
    const url = `tag/list?tags=${tags}`;
    const modelsQuery = modelsToFetch.reduce((query, model) => {
        switch (model) {
            case fetchableModelsForTags.article:
                return `${query}&models=article`;
            case fetchableModelsForTags.event:
                return `${query}&models=event`;
            default:
                return query;
        }
    }, '');
    return url + modelsQuery;
};
class ArticleEventList extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            error: false
        };
    }
    componentDidMount() {
        const { data, modelsToFetchForTags = defaultModelsToFetch, addToCt } = this.props;
        const tags = data.getIn(["content", "tag"]);
        const url = getListTagURL(tags, modelsToFetchForTags);
        fetchAPIData(url)
            .then(response => {
            let modelData = response.getIn(["data", "list"]);
            if (this.props.data.getIn(["content", "sort_alphabetical"])) {
                modelData = modelData.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            const tagToBeSorted = tags.split(',')[0];
            modelData = modelData.sort(sortEventByTagCarousalPosition(tagToBeSorted));
            const normalizeFn = normalizeDataToCommonSchema;
            const cards = modelData.map(normalizeFn);
            this.setState({
                cards: cards,
                title: response.getIn(["data", "meta", 0, "name"])
            });
        })
            .catch(e => {
            console.error(e);
            this.setState({
                error: true
            });
        });
    }
    render() {
        const data = this.props.data;
        const tags = data.getIn(["content", "tag"]);
        if (this.state.error) {
            return <Error />;
        }
        if (this.state.cards) {
            if (this.state.cards.size === 0) {
                return <CardListEmpty slug={data.getIn(["content", "tag"])}/>;
            }
            const carouselType = data.getIn(["metadata", "display"]);
            const showTitle = data.getIn(["metadata", "show_title"]);
            if (carouselType === "grid") {
                if (showTitle === "false") {
                    return <CardListWithoutTitle title={this.state.title} cards={this.state.cards}/>;
                }
                else {
                    return <CardListWithTitle title={this.state.title} cards={this.state.cards}/>;
                }
            }
            else {
                return (<DataCardCarousel title={this.state.title} cards={this.state.cards} carouselType={carouselType} showTitle={showTitle} headerClass={this.props.headerClass} addToCt={this.props.addToCt} tags={tags}/>);
            }
        }
        else {
            return <CardListShell />;
        }
    }
}
export default ArticleEventList;
