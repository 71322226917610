/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import classNames from "classnames";
import { mediaFeatures } from "../styles/common";
import EventCard from "./EventCard";
const CardListLoaderContainerStyle = `
    display: flex;
    width: 100vw;
`;
const CardListShell = `
    &:nth-of-type(2) {
        @media screen and ${mediaFeatures["for-mobile"]} {
            display: none;
        }
    }
    &:nth-of-type(3) {
        @media screen and ${mediaFeatures["for-mobile-and-tablet"]} {
            display: none;
        }
    }
`;
// Card shimmer for ELP pages
// Show 1 for mobile, 2 for tablet and 3 for pc and above
const CardListLoader = ({ itemType, section }) => {
    return (<div css={css(CardListLoaderContainerStyle)}>
			<li css={css(CardListShell)} className={classNames("card-list-item", itemType)} key={`no-data-card-list-1-${section}`}>
				<EventCard cardData={undefined}/>
			</li>
			<li css={css(CardListShell)} className={classNames("card-list-item", itemType)} key={`no-data-card-list-2-${section}`}>
				<EventCard cardData={undefined}/>
			</li>
			<li css={css(CardListShell)} className={classNames("card-list-item", itemType)} key={`no-data-card-list-3-${section}`}>
				<EventCard cardData={undefined}/>
			</li>
		</div>);
};
export default CardListLoader;
