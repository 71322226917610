/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import classNames from "classnames";
import Image from "./Image";
import { mediaFeatures, stdCardBorder } from "../styles/common";
import { medFontSize } from "../styles/typography";
const InsiderActionStyles = `
	width: 80%;
	margin: 60px auto;
	@media screen and ${mediaFeatures["for-mobile"]} {
		width: 90%;
		margin: 40px auto;
	}
`;
const TextActionContainerStyles = `
	border: ${stdCardBorder};
	padding: 30px;
	@media screen and ${mediaFeatures["for-large-tablet"]}{
		padding: 0;
		border: 0;
		display: flex;
		align-items: center;
	}	
`;
const TextActionSectionStyles = `
	text-align: center;
	@media screen and ${mediaFeatures["for-large-tablet"]}{
		text-align: left;
			display: block;
			width: 46%;
	}
	@media screen and ${mediaFeatures["for-pc"]}{
		width: 58%;
	}
`;
const TextActionTitleStyles = `
	font-size: 1.5rem;
	margin-bottom: 1.75rem;
	font-weight: 500;
	@media screen and ${mediaFeatures["for-mobile"]} {
		font-size: ${medFontSize};
	}
`;
const TextActionDescStyles = `
	display: none;
	@media screen and ${mediaFeatures["for-large-tablet"]}{
		display: block;
		line-height: 1.7;
		font-weight: 400;
		font-size: 1.11rem;
	}
`;
const TextActionImageStyles = `
	position: absolute;
	z-index: -1;
`;
const TextActionPanel = (props) => {
    const hasRichDescription = !!props.richDescription;
    return (<section className={classNames(props.className)} css={css(InsiderActionStyles)}>
			<div css={css(TextActionContainerStyles)}>
				<div css={css(TextActionSectionStyles)}>
					<h4 css={css(TextActionTitleStyles)}>{props.title}</h4>
					{!hasRichDescription && <p css={css(TextActionDescStyles)}>{props.text}</p>}
					{hasRichDescription && (<div css={css(TextActionDescStyles)} dangerouslySetInnerHTML={{ __html: props.richDescription }}/>)}
					{props.shareComponent}
				</div>
				{props.actionComponent}
			</div>
			{props.imgSrc && (<Image css={css(TextActionImageStyles)} src={props.imgSrc} imgWidth={props.imgWidth} imgWidths={props.imgWidths} autoPlay={true}/>)}
		</section>);
};
export default TextActionPanel;
