import React from "react";
import LargeEventCard from "../components/LargeEventCard";
import Error from "../components/ErrorComponent";
import Loader from "../components/Loader";
import { getComputedState, getBuyButtonText } from "../helpers/constants";
import { fromJS } from "immutable";
import { connect } from "react-redux";
class ArticleEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }
    componentDidMount() {
        fetch(`/event/getEventDetailsForClient/${this.props.data.getIn(["content", "eventId"])}`)
            .then(response => response.json())
            .then(response => {
            this.setState({ event: response.data });
        })
            .catch(e => {
            console.error(e);
            this.setState({
                error: true
            });
        });
    }
    render() {
        var _a, _b, _c;
        if (this.state.error) {
            return <Error />;
        }
        if (this.state.event) {
            const event = this.state.event;
            let eventState = getComputedState(fromJS(event));
            const { buyButtonHelperText, buyButtonText, disabled } = getBuyButtonText(eventState, event.action_button_text, event.is_rsvp);
            const cardData = {
                slug: event.slug,
                name: event.name,
                horizontal_cover_image: event.horizontal_cover_image,
                price_display_string: event.price_display_string === "0" ? "FREE" : event.price_display_string,
                date: (_a = event === null || event === void 0 ? void 0 : event.venue) === null || _a === void 0 ? void 0 : _a.date_string,
                venue_name: (_b = event === null || event === void 0 ? void 0 : event.venue) === null || _b === void 0 ? void 0 : _b.name,
                action_button: buyButtonText,
                action_button_helper_text: buyButtonHelperText,
                action_button_disabled: disabled,
                platform: (_c = this.props) === null || _c === void 0 ? void 0 : _c.platform,
                city: event.city,
                type: event.event_type
            };
            return <LargeEventCard {...cardData}/>;
        }
        return <Loader />;
    }
}
const mapStateToProps = (state) => ({
    platform: state.getIn(["uiState", "platform"])
});
export default connect(mapStateToProps, null)(ArticleEvent);
