import React, { Component } from "react";
import { getBuyButtonData } from "./common";
import EDPBuyButton from "../EDPBuyButton";
class BuyButton extends Component {
    render() {
        const { buyButtonText, buyButtonHelperText, hrefLink, disabled, isExternalBuyLink = false } = getBuyButtonData(this.props);
        return (<EDPBuyButton text={buyButtonText} href={hrefLink} disabled={disabled} htmlCaption={buyButtonHelperText} name={this.props.name} slug={this.props.slug} price_display_string={this.props.price_display_string} isFree={this.props.isFree} type={this.props.type} isFromApp={this.props.isFromApp} openAppBuyButton={this.props.openAppBuyButton} skipContentBeforeRSVPButton={this.props.skipContentBeforeRSVPButton} shouldBlockiOSPurchase={this.props.shouldBlockiOSPurchase} eventData={this.props.eventData} selected_city={this.props.selectedCity} queryParams={this.props.queryParams} isExternalBuyLink={isExternalBuyLink}/>);
    }
}
export default BuyButton;
