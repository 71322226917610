/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import Modal from "./Modal";
import { colors, mediaFeatures } from "../styles/common";
const BottomSheetModal = ({ handleDismiss, children, showStickyBtn = false, btnChildren, isMobileApp = false, canClose = true, modalBodyColor = '', largeOverlay = false, }) => {
    const [hasTransitionedIn, setHasTransitionedIn] = useState(false);
    useEffect(() => {
        if (!hasTransitionedIn) {
            setHasTransitionedIn(true);
        }
    }, []);
    return (<div css={css([OverrideModalStyle(showStickyBtn), hasTransitionedIn && scaleIn])}>
			<Modal shouldDisplay handleDismiss={handleDismiss} closeButton={closeButton(showStickyBtn)} canClose={canClose}>
				<div css={css([modalBody(isMobileApp, largeOverlay, showStickyBtn), BodyColor(modalBodyColor)])}>{children}</div>
				{showStickyBtn && <div css={css([modalStickyBtn(isMobileApp)])}>{btnChildren}</div>}
			</Modal>
		</div>);
};
export default BottomSheetModal;
const OverrideModalStyle = (showStickyBtn) => `
	position: absolute;
    .standard-modal{
        border-radius: ${showStickyBtn ? "15px" : "30px"};
        box-shadow: 0px -15px 30px 0px rgba(0, 0, 0, 0.10);
		overflow: hidden;
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
		@media screen and (max-width: 34.9em) {
			border-radius: ${showStickyBtn ? "15px 15px 0 0" : "30px 30px 0 0"};
			height: unset;
			background-image: unset;
			top: unset;
			// bottom: 0px;
			bottom: -50%;
			transition: opacity 0.1s ease-in-out, bottom 0.35s ease-in-out;
		}
    }
`;
const scaleIn = `
	.standard-modal {
		opacity: 1;
		@media screen and (max-width: 34.9em) {
			bottom: 0;
		}
	}
`;
const closeButton = (showStickyBtn) => css `
	background-image: url("/assets/images/icons/cross-black.svg");
	background-color: #f3f3f5;
	background-size: 12px;
	${showStickyBtn ? `
		top: 15px !important;
		right: 15px !important;
	` : `
		top: 30px !important;
		right: 30px !important;
		`}
	// top: 25px !important;
	// right: 30px !important;
	width: 36px;
	height: 36px;
	border-radius: 12px;
	padding: 10px;
	// @media screen and (max-width: 34.9em) {
	// 	right: 15px !important;
	// 	top: 15px !important;
	// }
`;
const modalBody = (isMobileApp, largeOverlay, isBtnShown) => `
	padding: 30px;
	// border-radius: 30px !important;
	background: ${colors.white};
	text-align: start;
	${isBtnShown ?
    `
		padding: 15px;
		padding-bottom: 0px;
		// border-radius: 15px 15px 0 0 !important;
		background: ${colors.insiderBGLight};
	` : ''}
	@media screen and ${mediaFeatures["for-mobile"]} {
		${!isBtnShown && `min-height: 18vh;`}
	}
	${largeOverlay ? `
		padding: 15px;
		@media screen and (max-width: 34.9em) {
			// min-height: 100vh;
			// max-height: 100vh;
			max-height: calc(100vh - 80px);
			min-height: calc(100vh - 80px);
		}
	` : ''}
`;
const BodyColor = (color) => `
${!!color ? `background: ${color};` : ''}
`;
const modalStickyBtn = (isMobileApp) => `
	padding: 15px;
	// border-radius: 0 0 15px 15px;
    border-top: 0.5px solid ${colors.insiderGrey};
	@media screen and ${mediaFeatures["for-mobile"]} {
		padding: 15px;
	}
`;
