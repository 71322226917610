import { connect } from "react-redux";
// components
import ImageList from "../components/ImageList";
import { isFromApp } from "../selectors";
const mapStateToProps = (state) => {
    return {
        isFromApp: isFromApp(state)
    };
};
const ImageListWithSubscription = connect(mapStateToProps)(ImageList);
export default ImageListWithSubscription;
