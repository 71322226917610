import React from "react";
import ItemCard from "../components/ItemCard";
import Error from "../components/ErrorComponent";
import Loader from "../components/Loader";
import { fromJS } from "immutable";
import { connect } from "react-redux";
class ItemCardWithSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }
    componentDidMount() {
        fetch(`/items/getItemDetailsForClient/${this.props.data.getIn(["content", "itemId"])}`)
            .then(response => response.json())
            .then(response => {
            this.setState({ item: fromJS(response.data) });
        })
            .catch(e => {
            this.setState({
                error: true
            });
        });
    }
    render() {
        if (this.state.error) {
            return <Error />;
        }
        if (this.state.item) {
            return <ItemCard cardData={this.state.item} metaClassName={this.props.metaClassName}/>;
        }
        return <Loader />;
    }
}
const mapStateToProps = (state) => ({
    platform: state.getIn(["uiState", "platform"])
});
export default connect(mapStateToProps, null)(ItemCardWithSubscription);
