import React from "react";
const SprayIcon = () => {
    return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.0888 13.4126C24.1377 13.5208 24.2061 13.6209 24.2924 13.7071C24.3591 13.774 24.4342 13.83 24.5148 13.8747L26.2319 14.866C26.7102 15.1422 27.3217 14.9783 27.5979 14.5C27.7396 14.2545 27.7654 13.9739 27.6923 13.7216C27.882 13.5396 28 13.2836 28 13C28 12.7164 27.882 12.4604 27.6923 12.2784C27.7654 12.0261 27.7396 11.7455 27.5979 11.5C27.3217 11.0217 26.7102 10.8578 26.2319 11.134L24.5148 12.1253C24.3622 12.2097 24.2297 12.3352 24.1356 12.4968C24.1185 12.5262 24.1028 12.5565 24.0887 12.5877L24.088 12.5891C24.028 12.7219 23.9994 12.8623 23.9996 13.0011C23.9997 13.138 24.0278 13.2764 24.0865 13.4074L24.0888 13.4126Z" fill="#F05B2B"/>
        <path d="M28.8299 9.63396C28.3517 9.9101 28.1878 10.5217 28.4639 11C28.7401 11.4783 29.3517 11.6422 29.8299 11.366L31.562 10.366C32.0403 10.0899 32.2042 9.47828 31.928 8.99998C31.6519 8.52169 31.0403 8.35782 30.562 8.63396L28.8299 9.63396Z" fill="#F05B2B"/>
        <path d="M33.1601 7.13396C32.6818 7.4101 32.5179 8.02169 32.794 8.49998C33.0702 8.97828 33.6818 9.14215 34.1601 8.86601L35.8921 7.86601C36.3704 7.58987 36.5343 6.97828 36.2581 6.49998C35.982 6.02169 35.3704 5.85782 34.8921 6.13396L33.1601 7.13396Z" fill="#F05B2B"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23 8.99999C23 8.67855 22.8455 8.3767 22.5847 8.18875C22.3239 8.0008 21.9887 7.94966 21.6838 8.05131L15.6838 10.0513C15.2754 10.1874 15 10.5696 15 11V16H14C13.4477 16 13 16.4477 13 17L13 20.2489C12.61 20.428 12.1653 20.7288 11.7407 21.2242C10.7724 22.354 10 24.3803 10 28C10 28.4054 10.2412 28.7544 10.5879 28.9114C10.215 29.5193 10 30.2345 10 31V38C10 38.7654 10.215 39.4807 10.5879 40.0886C10.2412 40.2456 10 40.5946 10 41C10 41.5523 10.4477 42 11 42H27C27.5523 42 28 41.5523 28 41C28 40.5946 27.7588 40.2456 27.4121 40.0886C27.785 39.4807 28 38.7654 28 38V31C28 30.2345 27.785 29.5193 27.4121 28.9114C27.7588 28.7544 28 28.4054 28 28C28 24.3803 27.2276 22.354 26.2593 21.2242C25.8347 20.7288 25.39 20.428 25 20.2489V17C25 16.4477 24.5523 16 24 16H23V8.99999ZM21.9961 18L22 18L22.0039 18H23V20H15V18H15.996L16 18L16.004 18L21.9961 18ZM21 12V10.3874L17 11.7208V16L21 16L21 14C20.4477 14 20 13.5523 20 13C20 12.4477 20.4477 12 21 12ZM13.8937 22.0414C13.9642 22.0139 14.0144 22.0036 14.0355 22.0001H23.9645C23.9856 22.0036 24.0358 22.0139 24.1063 22.0414C24.2417 22.094 24.478 22.2192 24.7407 22.5258C25.2167 23.0811 25.8498 24.3205 25.9771 27H12.0229C12.1502 24.3205 12.7833 23.0811 13.2593 22.5258C13.522 22.2192 13.7583 22.094 13.8937 22.0414ZM13.9972 29C12.8939 29.0015 12 29.8963 12 31L12 38C12 39.1046 12.8954 40 14 40H24C25.1046 40 26 39.1046 26 38L26 31C26 29.8963 25.1061 29.0015 24.0028 29H13.9972Z" fill="#F05B2B"/>
        <path d="M29 13C29 12.4477 29.4477 12 30 12H32C32.5523 12 33 12.4477 33 13C33 13.5523 32.5523 14 32 14H30C29.4477 14 29 13.5523 29 13Z" fill="#F05B2B"/>
        <path d="M34 13C34 12.4477 34.4477 12 35 12H37C37.5523 12 38 12.4477 38 13C38 13.5523 37.5523 14 37 14H35C34.4477 14 34 13.5523 34 13Z" fill="#F05B2B"/>
        <path d="M28.4639 15C28.7401 14.5217 29.3517 14.3578 29.8299 14.634L31.562 15.634C32.0403 15.9101 32.2042 16.5217 31.928 17C31.6519 17.4783 31.0403 17.6422 30.562 17.366L28.8299 16.366C28.3517 16.0899 28.1878 15.4783 28.4639 15Z" fill="#F05B2B"/>
        <path d="M32.794 17.5C33.0702 17.0217 33.6818 16.8578 34.1601 17.134L35.8921 18.134C36.3704 18.4101 36.5343 19.0217 36.2581 19.5C35.982 19.9783 35.3704 20.1422 34.8921 19.866L33.1601 18.866C32.6818 18.5899 32.5179 17.9783 32.794 17.5Z" fill="#F05B2B"/>
    </svg>;
};
export default SprayIcon;
