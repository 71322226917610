/** @jsx jsx */
import { jsx } from "@emotion/core";
import { generateGoogleMapsLink } from "../helpers/linkGenerators";
import { colors } from "../styles/common";
import RichTextTitle from "./RichTextTitle";
import { openInBrowserForApp } from "../helpers/appBridge";
import { LinkWithH5Support } from "./LinkWithH5Support";
const directionButtonStyle = {
    display: "block",
    textAlign: "center",
    borderRadius: "3px",
    border: `solid 1px ${colors.greyBorder}`,
    padding: "0.08em",
    maxWidth: "130px",
    marginTop: "8px"
};
const directionButtonTextStyle = {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0.6px",
    color: colors.black
};
const VenueCard = ({ latitude, longitude, name, address, header, isFromApp }) => {
    // this is venue to be announced state
    // todo replace with a nicer venue coming soon component
    // We do a !latitude here to match latitude = 0 (coming soon venue)
    // and to match where venue itself is undefined (when the event has expired)
    if (!latitude || !longitude) {
        return null;
    }
    const googleMapsLink = generateGoogleMapsLink({ latitude, longitude });
    return (<section id="section-venue" className="venue-wrapper">
			{/*<span style="display: none;" data-sj-meta-lat=""></span>*/}
			{/*<span style="display: none;" data-sj-meta-lng="{{longitude}}"></span>*/}
			<section className="text text-align-left custom-widget-title">
				<RichTextTitle text={header}/>
				<h3>{name}</h3>
				<p>{address}</p>
				<LinkWithH5Support css={Object.assign({}, directionButtonStyle)} target="_blank" href={googleMapsLink} onClick={e => {
            if (isFromApp) {
                e.preventDefault();
                openInBrowserForApp(googleMapsLink);
            }
        }}>
					<span css={Object.assign({}, directionButtonTextStyle)}>GET DIRECTIONS</span>
				</LinkWithH5Support>
			</section>

			{/* temporary replacing this image with a button to get direction..*/}
			{/* because our gmap image api account quota exceeds on high traffic*/}

			{/*<Image*/}
			{/*	src={generateGoogleMapsImageLink({ latitude, longitude })}*/}
			{/*	className={`image image-left image-size-big venue-map`}*/}
			{/*	href={generateGoogleMapsLink({ latitude, longitude })}*/}
			{/*	captionClassName="image-caption"*/}
			{/*	optimize={false}*/}
			{/*	lazy={true}*/}
			{/*/>*/}
		</section>);
};
VenueCard.defaultProps = {
    header: "Venue"
};
export default VenueCard;
