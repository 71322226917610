/** @jsx jsx */
import { colors } from "../../styles/common";
export const buyButtonColorStyle = {
    backgroundColor: colors.primaryPink
};
export const fontStyle = {
    fontStretch: "condensed",
    fontFamily: "korolev-condensed, sans-serif;"
};
export const inviteButtonColorStyle = {
    color: colors.primaryPink,
    border: `1px  ${colors.primaryPink} solid`
};
