/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { container } from "../styles/base/container";
import { colors, InterFont, mediaFeatures } from "../styles/common";
import { getSelectedCityName, isFromApp } from "../selectors/index";
import { connect } from "react-redux";
import { generateLinkForAllEvents } from "../helpers/linkGenerators";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
import { get } from "lodash";
import { ISLGoTags } from "../helpers/constants/common";
const CardListEmptyContainer = `
	${container}
	border: 1px solid ${colors["faintGrey"]};
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 20px;
`;
const CardListEmptyTitle = `
	font-family: ${InterFont};
	font-weight: 700;
	font-size: 25px;
	line-height: 28px;
	display: flex;
	align-items: center;
    @media screen and ${mediaFeatures["for-mobile"]} {
		font-size: 19px;
		line-height: 22px;
	}
`;
const CardListEmptySubText = `
	font-family: ${InterFont};
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: ${colors["subTextGrey"]};
	margin-top: 15px;
	@media screen and ${mediaFeatures["for-mobile"]} {
		font-size: 14px;
		line-height: 16px;
		margin-top: 8px;
	}
`;
const CardListEmptyEyeIcon = `
	margin-left: 5px;
	height: 28px;
	@media screen and ${mediaFeatures["for-mobile"]} {
		height: 22px;
	}
`;
const Link = `
	color: ${colors.insiderBlue};
`;
const CardListEmpty = ({ selectedCity = "online", slug = "", isFromApp = false }) => {
    const isISLTag = ISLGoTags.includes(slug);
    return (<div css={css(CardListEmptyContainer)}>
			<div css={css(CardListEmptyTitle)}>
				{isISLTag ? (`Ticket Sale for Upcoming Matches Coming Soon!`) : (<React.Fragment>
						{`Nothing To See Here`}
						<img css={css(CardListEmptyEyeIcon)} src="/assets/img/EyeIcon.png"/>
					</React.Fragment>)}
			</div>
			<div css={css(CardListEmptySubText)}>
				{isISLTag ? (`Stay Tuned!`) : (<React.Fragment>
						{`No events exist at the moment. `}
						{!isFromApp && selectedCity && (<React.Fragment>
								Meanwhile, check out
								<InsiderLink css={css(Link)} href={`${generateLinkForAllEvents(selectedCity)}`}>
									{` other events `}
								</InsiderLink>
								in your city.
							</React.Fragment>)}
					</React.Fragment>)}
			</div>
		</div>);
};
const mapStateToProps = (state, ownProps) => ({
    selectedCity: getSelectedCityName(state),
    isFromApp: isFromApp(state),
});
const CardListEmptyWithSubscription = connect(mapStateToProps, null)(CardListEmpty);
export default CardListEmptyWithSubscription;
